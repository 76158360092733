<template>
  <div ref="rank" class="rank">
    <cube-scroll
      ref="scroll"
      :data="list"
      :options="{ click: true, bounce: false }"
    >
      <div v-show="!list.length" class="not-data">暂无排行榜数据</div>
      <ul v-show="list.length" class="list">
        <li v-for="(item, inx) in list" :key="inx" class="item">
          <div class="top">
            <img v-if="inx == 0" :src="rankAvatarList[0]" width="24" />
            <img v-if="inx == 1" :src="rankAvatarList[1]" width="24" />
            <img v-if="inx == 2" :src="rankAvatarList[2]" width="24" />
            <span v-if="inx > 2" v-text="inx" />
          </div>
          <div class="avatar">
            <img
              :src="item.avatar ? resetUrl(item.avatar) : avatar"
              width="40"
            />
          </div>
          <div class="text">
            <h3 class="name ellips">
              {{ item.nickName }} <level :level="item.lv" />
            </h3>
            <p class="num" v-text="`贡献 ${item.num * 10}`" />
          </div>
          <div class="icon">
            <img
              v-if="item.giftWeeklyBestSellSort == 2"
              src="./up.png"
              width="10"
            />
            <img
              v-else-if="item.giftWeeklyBestSellSort == 1"
              src="./down.png"
              width="10"
            />
            <img v-else src="./vertical.png" width="14" />
          </div>
        </li>
      </ul>
    </cube-scroll>
  </div>
</template>
<script>
import level from "@/components/room/icon/level/level";
import { getWeekRank } from "@/api/room";
import { avatar } from "@/api/config";
import { mapState } from "vuex";
export default {
  components: {
    level
  },
  data() {
    return {
      list: [],
      avatar,
      rankAvatarList: [
        require("@/assets/img/rank-1.png"),
        require("@/assets/img/rank-2.png"),
        require("@/assets/img/rank-3.png")
      ]
    };
  },
  computed: {
    ...mapState({
      room: state => state.room.roomInfo
    })
  },
  mounted() {
    this._getWeekRank();
  },
  methods: {
    _getWeekRank() {
      getWeekRank(this.room.id).then(res => {
        this.list = res.object;
        this.$nextTick(() => {
          this.$refs.scroll.refresh();
        });
      });
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    }
  }
};
</script>
<style lang="stylus" scoped>
.rank
  position absolute
  top 4px
  right 0
  bottom 4px
  left 0
  overflow hidden
  .not-data
    padding 25px 0
    text-align center
  .list
    padding-top 10px
    .item
      display flex
      margin-bottom 8px
      &:nth-of-type(1)
        .top
          line-height 56px
        .avatar
          img
            width 56px
            height 56px
            border 2px solid #F2B955
        .icon
          line-height 56px
      &:nth-of-type(2)
        .top
          line-height 49px
        .avatar
          img
            width 49px
            height 49px
            border 2px solid #A0B9D9
        .icon
          line-height 49px
      &:nth-of-type(3)
        .top
          line-height 49px
        .avatar
          img
            width 49px
            height 49px
            border 2px solid #E0AB92
        .icon
          line-height 49px
      .top
        width 56px
        line-height 40px
        text-align center
        overflow hidden
        img
          display inline-block
          vertical-align middle
        span
          display inline-block
          width 24px
          height 24px
          background #D7D7D7
          line-height 24px
          border-radius 50%
          text-align center
          font-size 14px
          color #6B6B6B
      .avatar
        width 56px
        margin-right 10px
        font-size 0
        text-align center
        img
          width 40px
          height 40px
          border 2px solid #D7D7D7
          border-radius 50%
      .text
        display flex
        flex 1
        flex-direction column
        justify-content center
        line-height 1.5
        overflow hidden
        .name
          font-size 14px
          color #383A40
        .num
          font-size 10px
          color #6B6B6B
      .icon
        flex 0 0 44px
        line-height 40px
        vertical-align middle
        text-align center
</style>
