<template>
  <div v-show="showFlag" id="giftsCanvas" class="gifts-canvas-box" />
</template>
<script>
import SVGA from "svgaplayerweb";
import { QINIUYUN } from "@/api/config";
import { mapState } from "vuex";
export default {
  data() {
    return {
      showFlag: false,
      playAnimatedList: []
    };
  },
  computed: {
    ...mapState({
      msg: state => state.room.roomGiftsMsg
    })
  },
  watch: {
    msg(msg) {
      if (
        msg.giftName == "火箭" ||
        msg.giftName == "飞机" ||
        msg.giftName == "轮船"
      ) {
        this.getAnimatedObject(msg);
      }
    }
  },
  destroyed() {
    this.hide();
    this.playAnimatedList = [];
  },
  methods: {
    show() {
      this.showFlag = true;
      this.$nextTick(() => {
        this.initSvga();
      });
    },
    hide() {
      this.showFlag = false;
    },
    getAnimatedObject(obj) {
      const name = obj.giftName;
      let svgaName = "";
      if (name == "火箭") {
        svgaName = "rocket";
      }
      if (name == "飞机") {
        svgaName = "plane";
      }
      if (name == "轮船") {
        svgaName = "ship";
      }
      this.playAnimatedList.push({ ...obj, svgaName });
      this.show();
    },
    initSvga() {
      if (!this.player) {
        this.player = new SVGA.Player("#giftsCanvas");
        this.parser = new SVGA.Parser("#giftsCanvas");
        this.player.loops = 1;
      }
      this.svgaPlay();
    },
    svgaPlay() {
      if (this.playAnimatedList.length <= 0) {
        this.hide();
        return;
      }
      if (this.state) return;
      this.state = true;
      const playAnimatedObject = this.playAnimatedList.shift();
      const name = playAnimatedObject.svgaName;
      this.parser.load(
        `${QINIUYUN}/svga/${name}.svga`,
        videoItem => {
          this.player.setVideoItem(videoItem);
          this.player.setContentMode("AspectFill");
          this.player.startAnimation();
          this.player.onFinished(() => {
            this.state = false;
            this.svgaPlay();
          });
        },
        err => {
          console.log(err);
        }
      );
    }
  }
};
</script>
<style lang="stylus" scoped>
.gifts-canvas-box
  position fixed
  top calc(100vw / (16/9) + 36px)
  right 0
  bottom 84px
  left 0
  z-index 11
</style>
