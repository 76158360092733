<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="room-guess-rank">
      <div class="top">
        <div class="switch">
          <div
            class="bg"
            :class="{ pl: rankTypeInx == 0, pr: rankTypeInx == 1 }"
          />
          <div
            v-for="(item, inx) in rankTypes"
            :key="inx"
            class="l"
            :class="{ light: inx == rankTypeInx }"
            v-text="item"
            @click="changeType(inx)"
          />
        </div>
        <i class="cubeic-close close" @click="hide" />
      </div>
      <div class="inner-switch">
        <div class="l">
          <div
            v-text="
              `统计本房间用户${rankTimeInx == 0 ? '本周' : '本月'}竞猜总${
                rankTypeInx == 0 ? '盈利' : '亏损'
              }前10名
              `
            "
          />
        </div>
        <div class="r">
          <div
            class="bg"
            :class="{ pl: rankTimeInx == 0, pr: rankTimeInx == 1 }"
          />
          <div
            v-for="(item, inx) in rankTimes"
            :key="inx"
            class="btn"
            :class="{ light: inx == rankTimeInx }"
            v-text="item"
            @click="changeTime(inx)"
          />
        </div>
      </div>
      <cube-scroll
        ref="scroll"
        :data="list"
        :options="{ click: true, bounce: false }"
      >
        <ul v-if="list.length > 0" class="list">
          <li v-for="(item, inx) in list" :key="inx" class="item">
            <div v-if="inx > 2" class="num">
              <span v-text="inx + 1" />
            </div>
            <div class="avatar">
              <img :src="item.avatar ? item.avatar : avatar" class="image" />
              <img v-if="inx < 3" :src="topImages[inx]" class="top" />
            </div>
            <div class="name">
              <span v-text="item.userNickName" />
            </div>
            <div class="lv">
              <level :level="item.lv" />
            </div>
            <div
              v-if="rankTypeInx == 0 && rankTimeInx == 0"
              class="income profit"
            >
              <span>周盈利</span>
              <span v-text="item.profit" />
            </div>
            <div
              v-if="rankTypeInx == 0 && rankTimeInx == 1"
              class="income profit"
            >
              <span>月盈利</span>
              <span v-text="item.profit" />
            </div>
            <div
              v-if="rankTypeInx == 1 && rankTimeInx == 0"
              class="income lost"
            >
              <span>周亏损</span>
              <span v-text="item.deficit" />
            </div>
            <div
              v-if="rankTypeInx == 1 && rankTimeInx == 1"
              class="income lost"
            >
              <span>月亏损</span>
              <span v-text="item.deficit" />
            </div>
          </li>
          <!-- <li class="item">
            <div class="num">
              <span>3</span>
            </div>
            <div class="avatar">
              <img src="http://qiniu.doupai.live/845df53e-6d70-4903-ba29-968d06e36b3e" class="image">
            </div>
            <div class="name">
              <span>天才樱木花道</span>
            </div>
            <div class="lv">
              <img src="./img/vip.png" class="lv" />
            </div>
            <div class="income">
              <div class="value">4648</div>
              <div class="txt">周亏损</div>
            </div>
          </li>
          <li class="item">
            <div class="num">
              <span>3</span>
            </div>
            <div class="avatar">
              <img src="http://qiniu.doupai.live/845df53e-6d70-4903-ba29-968d06e36b3e" class="image">
            </div>
            <div class="name">
              <span>天才樱木花道</span>
            </div>
            <div class="income">
              <div class="value">4648</div>
              <div class="txt">周亏损</div>
            </div>
          </li> -->
        </ul>
        <ul v-else class="list">
          <div class="not-data">暂无数据</div>
        </ul>
      </cube-scroll>
    </div>
  </transition>
</template>
<script>
import level from "@/components/room/icon/level/level";
import {
  weeklyBestProfit,
  monthBestProfit,
  weeklyBestDeficit,
  monthBestDeficit
} from "@/api/room";
export default {
  components: {
    level
  },
  data() {
    return {
      showFlag: false,
      rankTypes: ["中奖榜", "天台榜"],
      rankTypeInx: 0,
      rankTimes: ["周榜", "月榜"],
      rankTimeInx: 0,
      list: [],
      avatar: require("@/assets/img/avatar.jpg"),
      topImages: [
        require("./img/top1.png"),
        require("./img/top2.png"),
        require("./img/top3.png")
      ]
    };
  },
  methods: {
    show() {
      this.showFlag = true;
      this.changeType(0);
    },
    hide() {
      this.showFlag = false;
    },
    changeType(inx) {
      this.rankTypeInx = inx;
      this.rankTimeInx = 0;
      if (this.rankTypeInx == 0) {
        this._weeklyBestProfit();
      } else {
        this._weeklyBestDeficit();
      }
    },
    changeTime(inx) {
      this.rankTimeInx = inx;
      if (this.rankTypeInx == 0 && this.rankTimeInx == 0) {
        this._weeklyBestProfit();
      }
      if (this.rankTypeInx == 0 && this.rankTimeInx == 1) {
        this._monthBestProfit();
      }
      if (this.rankTypeInx == 1 && this.rankTimeInx == 0) {
        this._weeklyBestDeficit();
      }
      if (this.rankTypeInx == 1 && this.rankTimeInx == 1) {
        this._monthBestDeficit();
      }
    },
    resetList() {
      this.list.forEach(item => {
        item.profit = this.parseNum(item.profit);
        item.deficit = this.parseNum(item.deficit);
      });
    },
    _weeklyBestProfit() {
      this.list = [];
      weeklyBestProfit().then(res => {
        if (!res) {
          return;
        }
        this.list = res.object;
        this.resetList();
      });
    },
    _monthBestProfit() {
      this.list = [];
      monthBestProfit().then(res => {
        if (!res) {
          return;
        }
        this.list = res.object;
        this.resetList();
      });
    },
    _weeklyBestDeficit() {
      this.list = [];
      weeklyBestDeficit().then(res => {
        if (!res) {
          return;
        }
        this.list = res.object;
        this.resetList();
      });
    },
    _monthBestDeficit() {
      this.list = [];
      monthBestDeficit().then(res => {
        if (!res) {
          return;
        }
        this.list = res.object;
        this.resetList();
      });
    },
    parseNum(num) {
      if (num < 10000) {
        return num;
      }
      return `${parseFloat(num / 10000).toFixed(1)}万`;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.room-guess-rank
  position fixed
  top calc(9.6vw + 100vw / (16 / 9))
  right 0
  bottom 0
  left 0
  background #F2F5F6
  display flex
  flex-direction column
  overflow hidden
  z-index 12
  .top
    position relative
    overflow hidden
    .switch
      position relative
      width 232px
      height 31px
      border 2px solid #1E88E4
      border-radius 15px
      margin 8px auto 11px
      text-align center
      font-size 0
      overflow hidden
      .bg
        position absolute
        top 50%
        width 115px
        height 26px
        background linear-gradient(to right, #2F6FDA, #2A9AFA)
        border-radius 13px
        margin-top -13px
        &.pl
          left 1px
        &.pr
          right 1px
      .l,.r
        position relative
        display inline-block
        vertical-align middle
        width 50%
        height 27px
        line-height 27px
        font-size 14px
        color #1E88E4
        &.light
          color #fff
    .close
      position absolute
      top 18px
      right 18px
      font-size 14px
      font-weight bold
      color #CECECE
  .inner-switch
    display flex
    justify-content space-between
    margin 0 13px 12px
    .l
      line-height 21px
      font-size 11px
      color #6B6B6B
    .r
      position relative
      width 69px
      height 21px
      line-height 21px
      background #CECECE
      border-radius 11px
      text-align center
      .bg
        position absolute
        top 0
        width 35px
        height 21px
        background linear-gradient(to right, #2F6FDA, #2A9AFA)
        border-radius 11px
        &.pl
          left 0
        &.pr
          right 0
      .btn
        position relative
        display inline-block
        width 50%
        color #383A40
        &.light
          color #fff
  .list
    position relative
    flex 1
    padding-top 174px
    .not-data
      font-size 17px
      text-align center
    .item
      .lv
        img
          width 42px
      .income
        font-size 11px
        color #1E88E4
        &.lost
          color #F6210B
      &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3)
        position absolute
        top 88px
        text-align center
        .avatar
          position absolute
          left 50%
          bottom calc(100% + 5px)
          transform translateX(-50%)
          .top
            position absolute
            left -4px
            bottom -1px
        .name
          font-size 14px
          color #383A40
        .income
          margin-top 15px
      &:nth-of-type(1)
        left 151px
        .avatar
          .image
            max-width 66px
            width 66px
            height 66px
            border-radius 50%
          .top
            width 74px
            max-width 74px
      &:nth-of-type(2),&:nth-of-type(3)
        .avatar
          .image
            max-width 52px
            width 52px
            height 52px
            border-radius 50%
          .top
            width 61px
            max-width 61px
      &:nth-of-type(2)
        left 49px
      &:nth-of-type(3)
        right 49px
      &:nth-of-type(n+4)
        display flex
        margin 0 14px
        padding 10px 0
        border-top 1px dashed #E0E0E0
        .num,.avatar,.name,.lv,.income
          display flex
          flex-direction column
          justify-content center
        .num
          margin-right 22px
          span
            width 24px
            height 24px
            line-height 24px
            background #F6AE68
            border-radius 50%
            font-size 14px
            text-align center
            color #fff
        .avatar
          margin-right 21px
          .image
            width 38px
            height 38px
            border-radius 50%
        .name
          margin-right 8px
          font-size 12px
          color #383A40
        .lv
          img
            width 42px
        .income
          flex 1
          line-height 12px
          font-size 11px
          text-align right
</style>
