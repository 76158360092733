<template>
  <div class="working">
    <div class="scroll-wrap">
      <div v-if="programNotData" class="not-data">
        <img src="./img/not.png" alt="暂无数据" />
        <p>节目单空空如也</p>
        <p>稍后再来看吧</p>
      </div>
      <cube-scroll
        v-else
        ref="scroll"
        :options="{ click: true, bounce: false }"
      >
        <div v-for="(item, inx) in programList" :key="inx" class="day">
          <div v-show="item.data.length" class="title">{{ item.name }}</div>
          <ul v-show="item.data.length" class="list">
            <li
              v-for="day in item.data"
              :key="day.id"
              class="item"
              :class="{ play: day.isNow, lonely: item.data.length == 1 }"
            >
              <i class="dot" />
              <span class="time">{{ day.time }}</span>
              <span class="desc">{{ day.name }}</span>
            </li>
          </ul>
        </div>
      </cube-scroll>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { _getProgramList } from "@/api/room";
export default {
  data() {
    return {
      programList: [],
      programNotData: true
    };
  },
  computed: {
    ...mapState({
      room: state => state.room.roomInfo
    })
  },
  mounted() {
    this.getProgramList();
  },
  methods: {
    async getProgramList() {
      if (this.programList.length) {
        this.getCurTime(this.programList);
        return;
      }
      const params = {
        roomId: this.room.id
      };
      const res = await _getProgramList(params);
      if (res) {
        this.getCurTime(res.object);
      }
    },
    getCurTime(list) {
      const redayPlayList = [];
      if (list.length) {
        const nowT = new Date().getTime();
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].data.length; j++) {
            const item = list[i].data[j];
            item.isNow = false;
            const endT = Date.parse(item.endTime);
            const playT = Date.parse(item.playTime);
            if (nowT > playT && nowT < endT) {
              item.isNow = true;
            } else if (nowT > endT) {
              list[i].data.splice(j, 1);
              j--;
            }
            redayPlayList.push(item);
          }
        }
      }
      this.programList = list;
      this.programNotData = redayPlayList.length <= 0;
    }
  }
};
</script>
<style lang="stylus" scoped>
.working
  position relative
  width 100%
  height 100%
  .scroll-wrap
    position absolute
    top 16px
    right 20px
    bottom 16px
    left 20px
    .not-data
      padding-top 62px
      text-align center
      img
        width 133px
        margin-bottom 13px
      p
        font-size 14px
        color #6B6B6B
  .day
    .title
      font-size 13px
      color #383A40
    .list
      padding 10px 0 10px 15px
      .item
        position relative
        display flex
        padding-bottom 15px
        line-height 15px
        color #6b6b6b
        &:last-child
          padding-bottom 0
        &.play
          &:after
            position absolute
            top -2.5px
            left -2.5px
            display block
            content ""
            width 20px
            height 20px
            background url(./img/play.png) no-repeat center center
            background-size 100%
        &.lonely
          .dot
            &:after
              top -8px
              height calc(100% + 8px + 8px)
        .dot
          flex 0 0 15px
          height 100%
          &:after
            position absolute
            top 0
            left 6px
            display block
            content ""
            width 3px
            height 100%
            background #b8b8b8
          &:before
            position absolute
            top 0
            display block
            content ""
            width 15px
            height 15px
            background #b8b8b8
            border-radius 50%
        .time
          padding 0 12px
        .desc
          position relative
</style>
