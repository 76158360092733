<template>
  <div v-if="showFlag" class="lottery-index">
    <div v-if="mode == 2" class="plus">
      <div class="content">
        <img class="gift-box" src="./img/box.png" alt="彩带+礼盒图标" />
        <img class="in-title" src="./img/in-title.png" alt="抽奖了" />
        <img class="ornament" src="./img/ornament.png" alt="装饰" />
        <img class="cd1" src="./img/cd1.png" alt="彩带1" />
        <img class="cd2" src="./img/cd2.png" alt="彩带2" />
        <div class="reward">
          <div v-if="info.prizeIcon" class="image">
            <img :src="info.prizeIcon" alt="礼物图片" />
          </div>
          <div class="text">
            <p class="timer">
              <img class="clock" src="./img/clock.png" alt="时间图标" />
              <span>{{ timer }}</span>
            </p>
            <p class="desc">{{ info.prizeName }}X{{ info.prizeAmount }}</p>
          </div>
        </div>
        <div v-if="info.lotteryDrawType == 2" class="rule">
          <div class="rule-title"><span>抽奖条件</span></div>
          <div class="rule-desc">赠送指定礼物即可参与抽奖</div>
          <div class="rule-gift">
            <img :src="info.giftIcon" alt="礼物图标" />
            <span>X1</span>
          </div>
        </div>
        <div v-if="info.lotteryDrawType == 1" class="rule">
          <div class="rule-title"><span>抽奖条件</span></div>
          <div class="rule-desc">发送下方口令即可参与抽奖</div>
          <div class="rule-cmd ellips">
            {{ info.lotteryDrawCmd }}
          </div>
        </div>
        <button v-if="info.lotteryDrawType == 1" class="btn" @click="copy">
          复制口令
        </button>
        <button
          v-if="info.lotteryDrawType == 2 && joinLotteryHistory != info.id"
          class="btn"
          @click="joinGiftsLottery"
        >
          赠送礼物
        </button>
        <button
          v-if="info.lotteryDrawType == 2 && joinLotteryHistory == info.id"
          class="btn"
        >
          已赠送，等待开奖
        </button>
        <button class="close" @click="changeMode(1)" />
      </div>
    </div>
    <div v-if="mode == 3" class="lucky">
      <div class="content">
        <img class="lucky-title" src="./img/lucky-title.png" alt="中奖啦" />
        <p class="lucky-slogn">恭喜您中奖啦！</p>
        <div class="reward">
          <div v-if="info.prizeIcon" class="image">
            <img :src="info.prizeIcon" alt="礼物图片" />
          </div>
          <div class="text">
            <p class="name">{{ lucky.prizeName }}</p>
            <p class="desc">奖励已经发送至您的账户上， 请查收。</p>
          </div>
        </div>
        <div class="good-lucky-guys">
          <div class="title">中奖名单</div>
          <div class="scroll-wrap">
            <cube-scroll
              :data="lotteryDrawRecordList"
              :options="{ click: true, bounce: false }"
            >
              <ul>
                <li v-for="item in lotteryDrawRecordList" :key="item.userId">
                  {{ item.showPhone }}
                </li>
              </ul>
            </cube-scroll>
          </div>
        </div>
        <button class="close" @click="hide" />
      </div>
    </div>
    <div v-if="mode == 4" class="again plus">
      <div class="content">
        <img class="again-title" src="./img/again-title.png" alt="下次再来" />
        <p class="sorry">很遗憾！你与奖品擦肩而过~</p>
        <div class="good-lucky-guys">
          <div class="title">中奖名单</div>
          <div class="scroll-wrap">
            <cube-scroll
              :data="lotteryDrawRecordList"
              :options="{ click: true, bounce: false }"
            >
              <ul>
                <li v-for="item in lotteryDrawRecordList" :key="item.userId">
                  {{ item.showPhone }}
                </li>
              </ul>
            </cube-scroll>
          </div>
        </div>
        <button class="close" @click="hide" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { _joinLottery, giveGifts } from "@/api/room";
export default {
  props: {
    timer: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showFlag: false,
      mode: 0, //1-mini,2-plus,3-lucky,4-again
      lotteryDrawRecordList: [],
      lucky: {}
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      room: state => state.room.roomInfo,
      ready: state => state.room.roomReady,
      joinLotteryHistory: state => state.room.joinLotteryHistory,
      info: state => state.room.lotteryInfo
    })
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST",
      setLotteryHistory: "room/SET_JOIN_LOTTERY_HISTORY",
      setChatText: "room/SET_CHAT_TEXT"
    }),
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    showPlus() {
      this.changeMode(2);
    },
    done() {
      this.changeMode(0);
      this.$emit("done");
    },
    async joinLottery() {
      const params = {
        liveId: this.room.id
      };
      const res = await _joinLottery(params);
      if (res) {
        this.setLotteryHistory(this.info.id);
        this.$createToast({
          txt: "参与抽奖成功，祝你好运",
          type: "correct"
        }).show();
      }
    },
    async joinGiftsLottery() {
      if (this.user.gold < this.info.giftPrice) {
        this.toast("金币不足");
        return;
      }
      const params = {
        liveId: this.room.id,
        giftId: this.info.giftId,
        num: 1
      };
      const res = await giveGifts(params);
      if (res) {
        this.joinLottery();
        this.getUserInfo();
      }
    },
    copy() {
      this.setChatText("");
      this.setChatText(this.info.lotteryDrawCmd);
      this.changeMode(1);
    },
    getLotteryResult(result) {
      if (!this.token) {
        return;
      }
      if (!this.showFlag) {
        this.show();
      }
      this.lotteryDrawRecordList = result.lotteryDrawRecordList;
      const luckyIndex = this.lotteryDrawRecordList.findIndex(item => {
        return this.user.id == item.userId;
      });
      if (luckyIndex > -1) {
        this.lucky = this.lotteryDrawRecordList[luckyIndex];
        this.changeMode(3);
        this.getUserInfo();
      } else {
        this.changeMode(4);
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.lottery-index
  position relative
  .mini
    position relative
    padding 2px 2px 12px
    margin-bottom 8px
    background #fff
    border 0px solid #CFCFCF
    border-radius 3px
    box-shadow 0px 1px 3px 0px rgba(0, 0, 0, 0.1)
    .logo
      width 40px
      height 34px
    .name
      position absolute
      right 0
      bottom 2px
      left 0
      font-size 10px
      color #000
      transform scale(.8)
    .timer
      position absolute
      top 24px
      right 0
      left 0
      font-size 10px
      font-weight bold
      color #fff
      // text-stroke 1px #FFFFFF
      // -webkit-text-stroke 1px #FFFFFF
  .plus
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    background rgba(0, 0, 0, .5)
    z-index 12
    .content
      position absolute
      top 152px
      left 50%
      margin-left -156px
      width 312px
      height 372px
      background url(./img/bg.png) no-repeat center center
      background-size 100%
      padding-top 52px
      .gift-box
        position absolute
        top -58px
        left 50%
        margin-left -102px
        width 204px
      .in-title
        position absolute
        top -17px
        left 50%
        margin-left -39px
        width 78px
      .ornament
        position absolute
        top -75px
        left 50%
        margin-left -127px
        width 254px
      .cd1
        position absolute
        bottom 0
        left -27px
        width 83px
      .cd2
        position absolute
        top 2px
        right -3px
        width 63px
      .reward
        display flex
        justify-content center
        align-items center
        .image
          flex 0 0 90px
          height 90px
          margin-right 8px
          img
            width 100%
            height 100%
        .text
          text-align left
          max-width 140px
          .timer
            line-height 19px
            img
              width 19px
              vertical-align middle
              margin-right 14px
            span
              vertical-align middle
              font-size 17px
              font-weight bold
              color #875940
          .desc
            font-size 21px
            font-weight bold
            color #8B42FF
            line-height 38px
            background linear-gradient(45deg, #7A492F 0%, #C97D3B 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
      .rule
        position absolute
        top 204px
        right 0
        left 0
        text-align center
        .rule-title
          span
            font-size 16px
            color #FFFFFF
            line-height 21px
            text-shadow 0px 1px 0px rgba(0, 0, 0, 0.35)
            background linear-gradient(0deg, #F4DEC6 0%, #C97D3B 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
        .rule-desc
          padding 5px 0 20px
          font-size 15px
          color #fff
        .rule-gift
          img
            margin-right 20px
            vertical-align middle
            width 43px
          span
            vertical-align middle
            font-size 21px
            font-weight bold
            color #fff
        .rule-cmd
          display inline-block
          max-width 90%
          padding 13px 10px
          background #F6DDC2
          border-radius 3px
          font-size 21px
          color #78482F
      .btn
        position absolute
        left 50%
        bottom 15px
        margin-left -87px
        width 174px
        height 35px
        line-height 35px
        background #F6DDC2
        padding 0
        border 0
        border-radius 3px
        font-size 18px
        font-weight bold
        color #78482F
  .close
    position absolute
    top -43px
    right 0
    width 32px
    height 32px
    background url(./img/close.png) no-repeat center center
    background-size 100%
    padding 0
    border 0
  .lucky
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    background rgba(0, 0, 0, .5)
    z-index 12
    .content
      position absolute
      top 66px
      left 50%
      margin-left -159px
      width 318px
      height 458px
      background url(./img/lucky.png) no-repeat center center
      background-size 100%
      padding-top 143px
      .lucky-title
        position absolute
        top 65px
        left 50%
        margin-left -45.5px
        width 91px
      .lucky-slogn
        position absolute
        top 110px
        right 0
        left 0
        text-align center
        font-size 18px
        font-weight bold
        color #F43021
      .reward
        display flex
        justify-content center
        align-items center
        .image
          flex 0 0 97px
          height 97px
          margin-right 26px
          img
            width 100%
            height 100%
        .text
          text-align left
          .name
            padding-bottom 9px
            font-size 17px
            font-weight bold
            color #875940
          .desc
            width 120px
            font-size 13px
            font-weight bold
            color #875940
            line-height 21px
            background linear-gradient(45deg, #7A492F 0%, #C97D3B 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
      .close
        top 45px
  .good-lucky-guys
    position absolute
    right 26px
    bottom  19px
    left 26px
    .title
      margin-bottom 13px
      text-align left
      font-size 16px
      color #FFFFFF
      line-height 21px
      text-shadow 0px 1px 0px rgba(0, 0, 0, 0.35)
      background linear-gradient(0deg, #F4DEC6 0%, #C97D3B 100%)
      -webkit-background-clip text
      -webkit-text-fill-color transparent
    .scroll-wrap
      position relative
      width 253px
      height 129px
      margin 0 auto
      padding 22px
      background url(./img/guys.png) no-repeat center center
      background-size 100%
      &:before
        position absolute
        top 0
        left -6px
        display black
        content ""
        width 265px
        height 9px
        background url(./img/bar.png) no-repeat center center
        background-size 100%
      ul
        display flex
        flex-wrap wrap
        li
          padding-bottom 11px
          flex 50%
          text-align left
          font-size 12px
          color #000
  .again
    .content
      .again-title
        position absolute
        top -22px
        left 50%
        margin-left -48px
        width 96px
      .sorry
        position absolute
        top 91px
        right 0
        left 0
        text-align center
        font-size 17px
        font-weight bold
        color #875940
</style>
