<template>
  <div v-if="showFlag" class="mini" @click.stop="open">
    <img class="icon" src="./img/icon.png" alt="投票" />
    <img
      v-if="status == 2"
      class="end"
      src="./img/end.png"
      alt="投票已经结束了"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      showFlag: false,
      status: 0
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
      this.$emit("hide");
    },
    open() {
      this.$emit("open");
    }
  }
};
</script>
<style lang="stylus" scoped>
.mini
  position relative
  padding 2px
  background #fff
  border-radius 3px
  .icon
    width 36px
  .end
    position absolute
    bottom -7px
    left 50%
    width 36px
    margin-left -18px
</style>
