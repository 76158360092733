<template>
  <span class="icon-fans">
    <img :src="src" alt="粉丝牌" />
  </span>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="stylus">
.icon-fans
  display inline-block
  height 15px
  line-height 15px
  vertical-align middle
  margin-right 6px
  font-size 0
  img
    height 100%
</style>
