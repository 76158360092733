<template>
  <span class="icon-gm" :class="{ super: roleType == 2 }" />
</template>
<script>
export default {
  props: {
    roleType: {
      type: Number,
      default: 1
    }
  }
};
</script>
<style lang="stylus" scoped>
.icon-gm
  display inline-block
  width 15px
  height 15px
  line-height 15px
  vertical-align middle
  margin-right 6px
  background url(./gm.png) no-repeat center center
  background-size 100%
  &.super
    background-image url(./super.png)
</style>
