var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showFlag)?_c('div',{staticClass:"room-guess-result"},[_c('div',{staticClass:"content"},[_c('h2',{staticClass:"title"},[_c('span',{staticClass:"text"},[_vm._v("竞猜结果")]),_c('i',{staticClass:"cubeic-close",on:{"click":_vm.hide}})]),_c('div',{staticClass:"tips"},[_vm._v(" 获得的DPP将直接发放到你的账户。 ")]),_c('ul',{staticClass:"list"},_vm._l((_vm.result.guessComThemeList),function(item,inx){return _c('li',{key:inx,staticClass:"item"},[_c('div',{staticClass:"theme",domProps:{"textContent":_vm._s(item.guessTheme)}}),_c('div',{staticClass:"win"},[_vm._v(" 胜利方："),_c('span',{domProps:{"textContent":_vm._s(
              item.resultOption == 1
                ? item.itemOneContent
                : item.resultOption == 2
                ? item.itemTwoContent
                : item.resultOption == 3
                ? '流局'
                : ''
            )}})]),_c('div',{staticClass:"state profit",domProps:{"textContent":_vm._s(item.resultIncome)}})])}),0),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"btn light",on:{"click":function($event){return _vm.toLink('/member/guess')}}},[_vm._v("竞猜详情")]),_c('div',{staticClass:"btn",on:{"click":_vm.hide}},[_vm._v("知道了")])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }