import TIM from "tim-js-sdk";

import { getIMLoginInfo } from "@/api/room";

class Im {
  constructor(room, onJionGroupSuccess, onMsg, onSendSuccess) {
    this.im = null;
    this.config = {
      SDKAppID: 1400114777
    };
    this.room = room;
    this.onJionGroupSuccess = onJionGroupSuccess;
    this.onMsg = onMsg;
    this.onSendSuccess = onSendSuccess;
    this.createIm();
  }
  createIm() {
    this.im = TIM.create(this.config);
    this.im.setLogLevel(1);
    this.im.on(TIM.EVENT.MESSAGE_RECEIVED, this.onMsg);
    this.im.on(TIM.EVENT.ERROR, this.getError);
    getIMLoginInfo().then(res => {
      if (!res) return;
      const loginOptions = {
        userID: res.object.identifier,
        userSig: res.object.userSig
      };
      const loginPromise = this.im.login(loginOptions);
      loginPromise
        .then(() => {
          this.onJoinGroup(this.room.roomGroupId);
        })
        .catch(imError => {
          console.warn("login error:", imError);
        });
    });
  }
  getError(event) {
    console.log(`im报错：${event.data.code}`);
  }
  loginOut() {
    if (!this.im) {
      return;
    }
    this.im.off(TIM.EVENT.MESSAGE_RECEIVED, this.onMsg);
    this.im.off(TIM.EVENT.ERROR, this.getError);
    this.im.logout();
  }
  onJoinGroup(id) {
    const options = {
      groupID: id,
      type: TIM.TYPES.GRP_AVCHATROOM
    };
    const joinGroup = this.im.joinGroup(options);
    joinGroup
      .then(imResponse => {
        switch (imResponse.data.status) {
          case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
            break;
          case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
            typeof this.onJionGroupSuccess === "function" &&
              this.onJionGroupSuccess();
            // console.log(`加群成功：${id}`);
            break;
          case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
            // console.log(`已经在群中：${id}`);
            break;
          default:
            break;
        }
      })
      .catch(imError => {
        console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
      });
  }
  sendMsg(msg) {
    if (!this.im) {
      return;
    }
    const message = this.im.createTextMessage({
      to: msg.roomGroupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        text: JSON.stringify(msg)
      }
    });
    const sendMsgPromise = this.im.sendMessage(message);
    sendMsgPromise
      .then(function(imResponse) {
        // 发送成功
        const message = imResponse.data.message;
        const msg = Object.assign({}, JSON.parse(message.payload.text), {
          id: message.ID,
          conversationType: message.conversationType,
          from: message.from,
          to: message.to
        });
        this.onSendSuccess(msg);
      })
      .catch(function(imError) {
        // 发送失败
        console.warn("sendMessage error:", imError);
      });
  }
  getGroupOnlineMemberCount(id) {
    let promise = this.im.getGroupOnlineMemberCount(id);
    promise
      .then(res => {
        console.log(res.data.memberCount);
      })
      .catch(imError => {
        console.warn("getGroupOnlineMemberCount error:", imError); // 获取直播群在线人数失败的相关信息
      });
  }
}

export default Im;
