<template>
  <transition name="slide-in">
    <div class="doupai-star-box">
      <div v-if="user.lv < 30">
        <div class="title border-bottom-1px">
          <span>等级达到30级，可解锁独特荣誉头衔特权哦~</span>
          <span @click="showMore">了解更多></span>
        </div>
      </div>
      <div v-else>
        <div class="title border-bottom-1px">
          <span>荣誉头衔</span>
          <span @click="showMore">了解更多></span>
        </div>
        <div class="star-image">
          <img src="./star.png" alt="斗牌之星" />
        </div>
        <div class="show-star">
          <cube-checkbox
            v-model="checked"
            shape="square"
            @input="userInfoUpdate"
          >
            隐藏荣誉头衔
          </cube-checkbox>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { update } from "@/api/user";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      checked: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  mounted() {
    this.checked = this.user.honorStatus != 1;
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    showMore() {
      this.$createDialog({
        type: "alert",
        title: "荣誉头衔特权",
        content: "等级达到30级，可解锁独特荣誉头衔特权，还有佩戴的选择权哦~",
        icon: "cubeic-alert"
      }).show();
    },
    async userInfoUpdate(honor) {
      const params = {
        honorStatus: honor ? 2 : 1
      };
      const res = await update(params);
      if (!res) return;
      this.getUserInfo();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.doupai-star-box
  position absolute
  right 0
  bottom 100%
  left 0
  padding 0 18px
  background #fff
  .title
    display flex
    justify-content space-between
    padding 7px 0
    font-size 12px
    color #6B6B6B
  .star-image
    padding-top 10px
    text-align center
    img
      width 90px
</style>
