<template>
  <div class="gifts-bar-box">
    <transition-group
      tag="ul"
      class="list"
      :css="false"
      @enter="enter"
      @leave="leave"
    >
      <li
        ref="barItem"
        v-for="(item, inx) in list"
        :key="item.id"
        :data-id="item.id"
        class="item"
      >
        <div
          v-show="inx < 2"
          class="bar"
          :class="item.giftId > 1 ? `lv${item.giftId}` : ''"
        >
          <div class="text">
            <p class="name">{{ item.nickName }}</p>
            <p class="desc">送出 {{ item.giftName }}</p>
          </div>
          <div class="icon">
            <img :src="gifList[item.giftId]" alt="礼物动图" />
          </div>
          <div class="count">X{{ item.click }}</div>
        </div>
      </li>
    </transition-group>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import Velocity from "velocity-animate";
export default {
  data() {
    return {
      list: [],
      gifList: {
        1: require("./../img/1.gif"),
        2: require("./../img/2.gif"),
        3: require("./../img/3.gif"),
        4: require("./../img/4.gif"),
        5: require("./../img/5.gif"),
        6: require("./../img/6.gif"),
        7: require("./../img/7.gif"),
        8: require("./../img/8.gif")
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info,
      msg: state => state.room.roomGiftsMsg
    })
  },
  watch: {
    msg(msg) {
      this.msgNormalized(msg);
    }
  },
  methods: {
    msgNormalized(msg) {
      const gift = msg;
      gift.id = uuidv4();
      gift.basePrice = 0;
      gift.price = gift.giftPrice;
      gift.click = 1;
      gift.timer = null;
      //用户自己送的礼物
      if (gift.userId == this.user.id) {
        gift.basePrice = 1000;
        gift.price = gift.basePrice + gift.giftPrice;
      }
      //是否连击
      const doubleInx = this.isGiftsDouble(gift);
      if (doubleInx > -1) {
        this.resetDoubleGiftsItem(doubleInx, gift);
      } else {
        this.list.push(gift);
      }
      //重新排序
      this.list = this.sort(this.list, "price");
    },
    isGiftsDouble(gift) {
      return this.list.findIndex(
        item => item.userId == gift.userId && item.giftName == gift.giftName
      );
    },
    resetDoubleGiftsItem(inx, newGift) {
      const gift = this.list[inx];
      const click = gift.click + 1;
      this.$set(this.list, inx, {
        ...gift,
        click,
        price: gift.giftPrice + newGift.giftPrice * click + newGift.basePrice
      });
      this.$nextTick(() => {
        const itemEl = this.$refs.barItem[inx];
        if (!itemEl) return;
        this.giftsItemClear(gift.id);
        const numEl = itemEl.querySelector(".count");
        this.numElementBouce(numEl);
      });
    },
    sort(array, key) {
      return array.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        return x < y ? 1 : x > y ? -1 : 0;
      });
    },
    enter(el, done) {
      Velocity(
        el,
        {
          translateX: -el.offsetWidth
        },
        {
          duration: 0
        }
      );
      Velocity(el, {
        opacity: 1,
        translateX: 10
      });
      Velocity(
        el,
        {
          translateX: 0
        },
        {
          duration: 350,
          complete: () => {
            this.giftsItemClear(el.dataset.id);
            done();
          }
        }
      );
    },
    giftsItemClear(id) {
      const elItem = this.list.find(item => item.id == id);
      if (elItem && elItem.timer) clearTimeout(elItem.timer);
      elItem.timer = setTimeout(() => {
        const elInx = this.list.findIndex(item => item.id == id);
        this.list.splice(elInx, 1);
        clearTimeout(elItem.timer);
      }, elItem.giftPlayDuration * 1000);
    },
    leave(el, done) {
      Velocity(
        el,
        {
          translateX: -el.offsetWidth
        },
        { complete: done }
      );
    },
    numElementBouce(el) {
      Velocity(
        el,
        {
          scale: 1.8,
          translateY: "-50%"
        },
        {
          duration: 40,
          easing: "linear"
        }
      );
      Velocity(
        el,
        {
          scale: 1,
          translateY: "-50%"
        },
        {
          duration: 40,
          easing: "linear"
        }
      );
    }
  }
};
</script>
<style lang="stylus" scoped>
.gifts-bar-box
  position absolute
  top 16px
  bottom 84px
  left 0
  z-index 1
  .list
    .item
      position relative
      display inline-block
      margin-bottom 28px
      opacity 0
      &:last-child
        margin-bottom 0
      .bar
        &.lv2
          background-image url(./img/lv2.png)
        &.lv3
          background-image url(./img/lv3.png)
        &.lv4
          background-image url(./img/lv4.png)
        &.lv5,&.lv6,&.lv7,&.lv8
            height 49px
            background-image url(./img/lv5.png)
            .icon
              img
                width 52px
                height 52px
            .count
              left 180px
      .bar
        position relative
        width 219px
        height 35px
        left -10px
        background url(./img/lv1.png) no-repeat center center
        background-size 100%
        .text
          display flex
          flex-direction column
          justify-content center
          height 100%
          padding-left 15px
          .name
            font-size 13px
            font-weight bold
            color #FEF28E
          .desc
            font-size 11px
            color #fff
        .icon
          position absolute
          top -12px
          left 124px
          img
            width 42px
            height 42px
        .count
          position absolute
          top 50%
          left 172px
          font-size 16px
          font-weight bold
          font-style italic
          // color #FEF28E
          color transparent
          -webkit-text-stroke 1px #6A190D
          text-stroke 1px #6A190D
          // background linear-gradient(180deg, #FFFEF3 0%, #FFF072 100%)
          -webkit-background-clip text
          // -webkit-text-fill-color transparent
          -webkit-text-fill-color #FFF072
          transform translateY(-50%)
</style>
