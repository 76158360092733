<template>
  <transition name="slide-in">
    <div v-if="showFlag" class="room-guess">
      <div class="top">
        <h2 class="title">
          <span class="icon"><img src="./img/star.png" class="star"/></span>
          <span class="text">互动竞猜<b>（自动赔率）</b></span>
          <button class="close" @click="hide">
            <i class="cubeic-close" />
          </button>
        </h2>
        <div v-show="tipsShowFlag" class="tips">
          <span class="icon">
            <img src="./img/trumpet.png" class="trumpet" />
          </span>
          <span class="text">
            投注数*封盘赔率*0.9+本金即是最终胜利返还奖金数
          </span>
          <button class="close" @click="tipsShowFlag = false">
            <i class="cubeic-close" />
          </button>
        </div>
      </div>
      <div class="list">
        <cube-scroll
          ref="scroll"
          :data="list"
          :options="{ click: true, bounce: false }"
        >
          <ul v-if="list.length > 0">
            <li v-for="item in list" :key="item.guessThemeId" class="item">
              <div class="theme">
                <h3 class="title" v-text="item.guessTheme" />
                <count-down
                  v-if="info.guessComStatus == 1 && item.noMoreBetStatus == 0"
                  class="down-time"
                  :tip-text="'后封盘'"
                  :seconds-txt="''"
                  :start-time="item.startTime"
                  :end-time="item.endTime"
                  @end_callback="_getRoomGuess"
                />
                <span
                  v-if="
                    info.guessComStatus == 1 &&
                      item.noMoreBetStatus == 1 &&
                      item.resultOption == 0
                  "
                  class="down-time"
                >
                  已封盘
                </span>
                <span
                  v-if="item.resultOption > 0"
                  class="profit"
                  v-text="
                    item.resultOption == 3 ? '流局' : `${item.resultIncome}`
                  "
                />
              </div>
              <div class="pk">
                <div
                  class="l"
                  :class="{ freeze: item.resultOption == 2 }"
                  @click="betShow(item, 1)"
                >
                  <div class="name" v-text="item.itemOneContent" />
                  <div class="odds" v-text="`赔率${item.oddsItemOne}`" />
                  <img
                    v-if="item.resultOption"
                    class="icon"
                    :src="
                      item.resultOption == 1
                        ? resultImg[0]
                        : item.resultOption == 3
                        ? resultImg[2]
                        : resultImg[1]
                    "
                  />
                </div>
                <div class="m">
                  <img src="./img/vs.png" class="vs" />
                </div>
                <div
                  class="r"
                  :class="{ freeze: item.resultOption == 1 }"
                  @click="betShow(item, 2)"
                >
                  <div class="name" v-text="item.itemTwoContent" />
                  <div class="odds" v-text="item.oddsItemTwo" />
                  <img
                    v-if="item.resultOption"
                    class="icon"
                    :src="
                      item.resultOption == 2
                        ? resultImg[0]
                        : item.resultOption == 3
                        ? resultImg[3]
                        : resultImg[1]
                    "
                  />
                </div>
              </div>
              <div class="press">
                <div v-show="item.rate > 0" class="bar" />
                <div
                  v-show="item.rate > 0"
                  class="inner"
                  :style="`width: ${item.rate}%`"
                />
                <span class="a" v-text="item.itemOneBetChips" />
                <span class="b" v-text="item.itemTwoBetChips" />
              </div>
            </li>
          </ul>
          <ul v-else class="list">
            <div class="not-data">暂无数据</div>
          </ul>
        </cube-scroll>
      </div>
      <div class="bottom">
        <div class="l">
          <div class="inner">
            <div>
              <img src="./img/dpp.png" class="dpp" />
              DPP: <b v-text="this.info.myDpp" />
            </div>
          </div>
        </div>
        <div class="r">
          <div class="d f">
            <div class="inner" @click="showRank">
              <div class="icon">
                <img src="./img/rank.png" class="rank" />
              </div>
              <div class="text">排行榜</div>
            </div>
          </div>
          <div class="d">
            <div class="inner" @click="toLink('/member/guess')">
              <div class="icon">
                <img src="./img/user.png" class="user" />
              </div>
              <div class="text">我的竞猜</div>
            </div>
          </div>
        </div>
      </div>
      <bet ref="bet" @done="betDone" />
      <rank ref="rank" />
    </div>
  </transition>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getRoomGuess } from "@/api/room";
import countDown from "@/components/base/countdown/countdown";
import bet from "@/components/room/guess/bet/index";
import rank from "@/components/room/guess/rank/index";
export default {
  components: {
    countDown,
    bet,
    rank
  },
  data() {
    return {
      showFlag: false,
      tipsShowFlag: true,
      info: {},
      list: [],
      resultImg: [
        require("./img/win.png"),
        require("./img/fail.png"),
        require("./img/flow.png"),
        require("./img/flow.png")
      ]
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      ready: state => state.room.roomReady,
      live: state => state.room.roomInfo
    })
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
      this._getRoomGuess();
    },
    hide() {
      this.showFlag = false;
    },
    _getRoomGuess() {
      const id = this.live.id;
      getRoomGuess(id).then(res => {
        if (!res) {
          return;
        }
        this.info = res.object;
        this.list = this.info.guessComThemeList;
        if (this.list.length > 0) {
          this.list.forEach(item => {
            item.rate = this.getRatio(
              item.itemOneBetChips,
              item.itemOneBetChips + item.itemTwoBetChips
            );
            item.chip = 0;
            item.startTime = new Date().getTime();
            item.endTime = new Date().getTime() + item.noMoreBetTimeMills;
            item.resultOption =
              item.modifyResultType > 0
                ? item.modifyResultType
                : item.resultType;
            item.resultIncome =
              item.income > 0 ? `+${item.income}` : item.income;
          });
        }
      });
    },
    getRatio(e, d) {
      return d > 0 ? parseInt((e / d) * 100) : 50;
    },
    betShow(item, pick) {
      if (this.info.guessComStatus > 1 || item.noMoreBetStatus > 0) {
        return;
      }
      if (pick == 1) {
        this.$refs.bet.info = {
          pick,
          guessComId: this.info.guessComId,
          guessThemeId: item.guessThemeId,
          theme: item.guessTheme,
          choice: item.itemOneContent,
          odds: item.oddsItemOne
        };
      } else {
        this.$refs.bet.info = {
          pick,
          guessComId: this.info.guessComId,
          guessThemeId: item.guessThemeId,
          theme: item.guessTheme,
          choice: item.itemTwoContent,
          odds: item.oddsItemTwo
        };
      }
      this.$refs.bet.show();
    },
    betDone() {
      this._getRoomGuess();
    },
    showRank() {
      this.$refs.rank.show();
    },
    toLink(link) {
      this.$router.push(link);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.room-guess
  position fixed
  top calc(9.6vw + 100vw / (16 / 9))
  right 0
  bottom 0
  left 0
  background #F2F5F6
  display flex
  flex-direction column
  overflow hidden
  z-index 12
  .top
    .title
      position relative
      padding 6px 8px 6px
      .icon
        display inline-block
        vertical-align bottom
        .star
          width 24px
      .text
        display inline-block
        font-size 17px
        font-weight bold
        b
          font-size 14px
      .close
        position absolute
        top 14px
        right 0
        border 0
        background none
        i
          font-weight bold
          color #AAABB2
    .tips
      position relative
      width 347px
      height 29px
      line-height 29px
      background #fff
      margin 0 auto 6.4px
      padding 0 10px
      border-radius 3px
      overflow hidden
      .icon
        display inline-block
        vertical-align middle
        .trumpet
          width 17px
      .text
        font-size 12px
        color #6B6B6B
      .close
        position absolute
        top 5.5px
        right 0
        border 0
        background none
        i
          display inline-block
          width 16px
          height 16px
          border 1px solid #AAABB2
          border-radius 50%
          color #AAABB2
  .list
    flex 1
    overflow hidden
    .not-data
      font-size 17px
      text-align center
    .item
      width 347px
      height 112px
      background #fff
      border-radius 3px
      margin 0 auto 6.4px
      padding 7px
      overflow hidden
      .theme
        position relative
        margin-bottom 6px
        .title
          font-size 16px
          color #383A40
        .down-time
          position absolute
          top 3px
          right 0
          font-size 11px
          color #383A40
        .profit
          position absolute
          top 2px
          right 0
          font-size 13px
          color #FB9426
      .pk
        display flex
        margin-bottom 8px
        .l,.r
          position relative
          display flex
          flex-direction column
          justify-content center
          flex 0 0 138px
          height 50px
          border-radius 3px
          text-align center
          color #fff
          .name
            font-size 17px
            font-weight bold
          .odds
            font-size 14px
          .icon
            position absolute
            width 32px
            top -10px
        .m
          display flex
          flex 1
          flex-direction column
          justify-content center
          align-items center
          .vs
            width 30px
          .state
            width 35px
            height 35px
            line-height 35px
            background #CECECE
            border-radius 50%
            text-align center
            font-size 10px
            color #383A40
        .l
          background linear-gradient(to right, #1C81FF, #2DBCFF)
          .icon
            right -10px
        .r
          background linear-gradient(to right, #E15133, #FF7137)
          .icon
            left -10px
        .freeze
          background linear-gradient(to right, #6D7071, #999C9D)
      .press
        position relative
        width 100%
        height 12px
        border-radius 6px
        background #CECECE
        overflow hidden
        .bar
          position absolute
          top 0
          left 0
          width 100%
          height 100%
          background linear-gradient(to right, #FF894E, #E15133)
          border-radius 6px
        .inner
          position absolute
          top 0
          left 0
          width 100%
          height 100%
          background linear-gradient(to right, #40D5FF, #1C81FF)
          border-radius 6px
        .a,.b
          display inline-block
          position absolute
          top 50%
          transform translateY(-50%)
          line-height 12px
          vertical-align middle
          font-size 11px
          color #fff
        .a
          left 5px
        .b
          right 5px
  .bottom
    display flex
    width 100%
    height  49px
    background #fff
    margin-top 6.4px
    padding 0 13px
    .inner
      display flex
      flex-direction column
      justify-content center
      height 100%
    .l
      flex 1
      .inner
        font-size 14px
        color #6B6B6B
        .dpp
          width 25px
          vertical-align middle
        b
          color #F6700B
    .r
      flex 1
      text-align right
      .d
        display inline-block
        height 100%
        text-align center
      .f
        margin-right 14px
      .inner
        .rank
          width 24px
        .user
          width 18px
        .text
          font-size 10px
          color #6B6B6B
</style>
