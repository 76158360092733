<template>
  <div class="tab">
    <div class="head">
      <div
        v-for="(item, inx) in tabMenus"
        :key="inx"
        :class="inx == tabActive ? 'active' : ''"
        v-text="item"
        class="item"
        @click="changeMode(inx)"
      />
      <!-- <div class="item follow">关注</div> -->
    </div>
    <div class="content">
      <chat v-if="tabActive == 0" />
      <rank ref="rank" v-if="tabActive == 1" />
      <working v-if="tabActive == 2" />
    </div>
  </div>
</template>
<script>
import chat from "@/components/room/chat/chat";
import rank from "@/components/room/rank/rank";
import working from "@/components/room/working";
import { mapState } from "vuex";
export default {
  components: {
    chat,
    rank,
    working
  },
  data() {
    return {
      tabMenus: ["聊天", "排行榜", "节目单"],
      tabActive: 0
    };
  },
  computed: {
    ...mapState({
      ready: state => state.room.roomReady
    })
  },
  watch: {
    ready(mode) {
      if (mode == 1) {
        this.tabActive = 0;
      }
    }
  },
  methods: {
    changeMode(inx) {
      this.tabActive = inx;
    }
  }
};
</script>
<style lang="stylus" scoped>
.tab
  position relative
  display flex
  flex-direction column
  flex 1
  width 100%
  overflow hidden
  .head
    display flex
    flex 0 0 40px
    justify-content center
    align-items center
    height 40px
    background #fff
    border-top 1px solid #eee
    .item
      position relative
      flex 1
      line-height 40px
      text-align center
      font-size 14px
      &.follow
        flex 0 0 81px
        background #ff7d23
        color #fff
        &:before
          position relative
          top -3px
          display inline-block
          vertical-align middle
          content "+"
          font-size 22px
      &.active
        color #1E88E4
        &:after
          display inline-block
          position absolute
          content " "
          width 15px
          height 3px
          background-color #1E88E4
          bottom 0
          left 50%
          transform translateX(-50%)
  .content
    position relative
    display flex
    flex 1
    width 100%
    height 100%
</style>
