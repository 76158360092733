<template>
  <transition name="slide-in">
    <div class="danmu-color-box">
      <div class="title border-bottom-1px">
        <span>彩色弹幕专属</span>
        <span @click="showMore">了解更多></span>
      </div>
      <ul class="list">
        <li
          v-for="(item, inx) in list"
          :key="inx"
          class="item"
          @click="selectDanmuColor(inx)"
        >
          <div class="icon" :style="`background-color: ${item.colorNum}`">
            <img
              v-show="user.chatColor == item.colorNum"
              class="sel"
              src="./danmu-sel.png"
              alt="选择"
            />
            <img
              v-show="item.isUnlock == 1"
              class="lock"
              src="./lock.png"
              alt="锁定"
            />
          </div>
          <div class="text">{{ item.title }}</div>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
import { getChatColorList, unLockChatColor } from "@/api/room";
import { update } from "@/api/user";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      showFlag: false,
      danmuColor: "#383A40",
      list: []
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info
    })
  },
  created() {
    if (!this.list.length) {
      this._getChatColorList();
    }
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
      if (!this.list.length) {
        this._getChatColorList();
      }
    },
    hide() {
      this.showFlag = false;
    },
    async _getChatColorList() {
      if (!this.token) return;
      this.list = [
        {
          colorNum: this.danmuColor,
          title: "普通",
          isUnlock: 2
        },
        {
          colorNum: "#ff7609",
          title: "会员",
          isUnlock: this.user.userType != 2 ? 1 : 2
        }
      ];
      const res = await getChatColorList();
      if (!res) return;
      this.list = [...this.list, ...res.object];
    },
    selectDanmuColor(inx) {
      const item = this.list[inx];
      if (item.colorNum == this.user.chatColor) {
        return;
      }
      if (item.isUnlock == 1 && item.title == "会员") {
        this.showTips("开通会员，解锁会员弹幕颜色, 是否前往?", () => {
          this.$router.push("/member/vip");
        });
        return;
      }
      if (item.isUnlock == 1 && item.dpp) {
        this.showTips(`解锁${item.titile}弹幕需消耗${item.dpp}斗币`, () => {
          this._unLockChatColor(inx);
        });
        return;
      }
      this.userInfoUpdate(item.colorNum);
    },
    async userInfoUpdate(color) {
      const params = {
        chatColor: color
      };
      const res = await update(params);
      if (!res) return;
      this.getUserInfo();
    },
    async _unLockChatColor(inx) {
      const item = this.list[inx];
      if (this.user.dppIntegral < item.dpp) {
        this.toast("斗币不足");
        return;
      }
      if (this.unLockLoading) return;
      this.unLockLoading = true;
      const params = {
        id: item.id
      };
      const res = await unLockChatColor(params);
      this.unLockLoading = false;
      if (!res) return;
      this.$set(this.list, inx, {
        ...item,
        isUnlock: 2
      });
      this.$createToast({
        txt: "解锁成功",
        type: "correct"
      }).show();
    },
    showTips(text, onConfirm) {
      this.$createDialog({
        type: "confirm",
        icon: "cubeic-alert",
        title: "提示",
        content: text,
        confirmBtn: {
          text: "确定",
          active: true,
          disabled: false,
          href: "javascript:;"
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;"
        },
        onConfirm
      }).show();
    },
    showMore() {
      this.$createDialog({
        type: "alert",
        title: "彩色弹幕专属",
        content:
          "开通会员可解锁会员专属弹幕。此外，消耗一定数量的斗币可以解锁更多的彩色专属弹幕，快来体验吧！",
        icon: "cubeic-alert"
      }).show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.danmu-color-box
  position absolute
  right 0
  bottom 100%
  left 0
  padding 0 18px
  background #fff
  .title
    display flex
    justify-content space-between
    padding 7px 0
    font-size 14px
    color #6B6B6B
  .list
    display flex
    flex-wrap wrap
    padding 8px 0
    .item
      margin-right 16px
      text-align center
      color #83849A
      &:last-child
        margin-right 0
      .icon
        width 28px
        height 28px
        line-height 28px
        margin-bottom 3px
        background #2C3E50
        border-radius 3px
        font-size 0
        .sel
          width 18px
          vertical-align middle
        .lock
          width 12px
          vertical-align middle
</style>
