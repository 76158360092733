<template>
  <div v-if="showFlag" class="banned-user">
    <div v-show="mode == 1" class="info">
      <div class="avatar">
        <img :src="info.avatar ? info.avatar : avatar" alt="用户头像" />
      </div>
      <div class="name">{{ info.nickName }}（Lv.{{ info.lv }}）</div>
      <p v-show="info.signature" class="signature ellips">
        {{ info.signature }}
      </p>
      <p class="register-time">注册时间：{{ info.registerTime }}</p>
      <p class="tags">
        <gm v-show="info.roleType > 0" :roleType="info.roleType" />
        <vip v-show="info.userType == 2" />
        <cute v-show="info.newPeople == 1" class="diff" />
        <fans v-show="info.fansImage" :src="info.fansImage" />
      </p>
      <div v-if="user.roleType > info.roleType" class="btns">
        <button v-if="accountState == 2" @click="_banUser(0)">解除禁言</button>
        <button v-else @click="mode = 2">禁止发言</button>
      </div>
      <p v-show="accountState == 2" class="banned-time-count">
        {{ userBanToPostTxt }}
      </p>
    </div>
    <div v-show="mode == 2" class="options">
      <p>
        <span class="label">禁言玩家：</span>
        <span class="name">{{ info.nickName }}</span>
      </p>
      <p>
        <span class="label">禁言时间：</span>
        <cube-select
          v-if="punishComboList.length"
          v-model="time"
          title="禁言时间"
          placeholder="请选择禁言时间"
          :options="punishComboList"
        />
      </p>
      <div class="btns">
        <button class="cancel" @click="mode = 1">取消</button>
        <button @click="_banUser(1)">确定</button>
      </div>
    </div>
    <button class="close" @click="hide">
      <i class="cubeic-close" />
    </button>
  </div>
</template>
<script>
import { avatar } from "@/api/config";
import gm from "@/components/room/icon/owner/gm";
import vip from "@/components/room/icon/vip/vip";
import cute from "@/components/room/icon/cute/cute";
import fans from "@/components/room/icon/fans/fans";
import { mapState } from "vuex";
import { queryUserInfo, banUser } from "@/api/room";
export default {
  components: {
    gm,
    vip,
    cute,
    fans
  },
  data() {
    return {
      showFlag: false,
      mode: 1, //1-用户信息，2-禁言选项
      info: {},
      avatar,
      accountState: 0,
      punishComboList: [],
      time: 0,
      userBanToPostTxt: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info,
      room: state => state.room.roomInfo
    })
  },
  methods: {
    show() {
      this.showFlag = true;
      this._queryUserInfo();
    },
    hide() {
      this.showFlag = false;
      this.mode = 1;
      this.info = {};
      this.timeCounter = null;
      this.userBanToPostTxt = "";
    },
    async _queryUserInfo() {
      const params = {
        userId: this.user.id,
        friendId: this.info.userId,
        roleType: this.user.roleType
      };
      const res = await queryUserInfo(params);
      if (!res) return;
      const data = res.object;
      this.accountState = data.accountState;
      if (data.accountState == 1) {
        for (let i = 0; i < data.punishComboList.length; i++) {
          this.punishComboList[i] = {
            ...data.punishComboList[i],
            value: data.punishComboList[i].id,
            text: data.punishComboList[i].punishName
          };
        }
        this.time = this.punishComboList[0].id;
      } else if (data.isPunishForever == 1) {
        this.userBanToPostTxt = "永久";
      } else {
        const punishEndTime = parseInt(data.punishEndTime);
        const nowTime = new Date().getTime();
        const ms = punishEndTime - nowTime;
        const d = parseInt(ms / 1000 / (24 * 60 * 60));
        const h = parseInt((ms / 1000 / (60 * 60)) % 24);
        const m = parseInt((ms / 1000 / 60) % 60);
        this.userBanToPostTxt = `${d}天${h}时${m}分后自动解禁`;
        if (ms > 0) {
          this.countTime(ms, nowTime);
        }
      }
    },
    countTime(ms, nowTime) {
      /** 倒计时 */
      const interval = 60000;
      let count = 0;
      const countDownStart = () => {
        count++;
        const offset = new Date().getTime() - (nowTime + count * interval);
        let nextTime = interval - offset;
        if (nextTime < 0) {
          nextTime = 0;
        }
        ms -= interval;
        const d = parseInt(ms / 1000 / (24 * 60 * 60));
        const h = parseInt((ms / 1000 / (60 * 60)) % 24);
        const m = parseInt((ms / 1000 / 60) % 60);
        this.userBanToPostTxt = `${d}天${h}时${m}分后自动解禁`;
        if (ms <= 0) {
          clearTimeout(this.timeCounter);
          this.hide();
        } else {
          this.timeCounter = setTimeout(countDownStart, nextTime);
        }
      };
      if (ms >= 0) {
        this.timeCounter = setTimeout(countDownStart, interval);
      }
    },
    async _banUser(type) {
      const params = {
        punishUserId: this.info.userId,
        roomGroupId: this.room.roomGroupId
      };
      if (type) {
        params.punishId = this.time;
      }
      const res = await banUser(params);
      if (!res) return;
      this.hide();
    }
  }
};
</script>
<style lang="stylus" scoped>
.banned-user
  position absolute
  top 52px
  right 59px
  left 59px
  border-radius 4px
  background #fff
  box-shadow 0 0 5px #e8e8e8
  padding 24px 0 13px
  text-align center
  z-index 20
  .info
    .avatar
      position absolute
      top -52px
      left 50%
      margin-left -38px
      width 76px
      height 76px
      line-height 76px
      border-radius 50%
      overflow hidden
      img
        width 100%
        height 100%
        border-radius 50%
    .name
      padding 8px 0
      font-size 18px
      color #000
    .signature
      padding-bottom 7px
      font-size 14px
      color #727171
    .register-time
      padding-bottom 13px
      font-size 12px
      color #727171
    .tags
      padding-bottom 10px
    .banned-time-count
      font-size 12px
      color #ff3636
  .options
    padding 0 20px
    p
      display flex
      align-items center
      padding 6px 0
      font-size 16px
      .name
        color #1e88e4
    .cube-select
      padding 0 20px 0 6px
      color #ff3636
  .btns
    padding-bottom 10px
    button
      padding 4px 20px
      background-color #07f
      border 1px solid #07f
      border-radius 20px
      color #fff
      &:first-child
        margin-right 6px
      &:last-child
         margin-right 0
      &.cancel
        background none
        border-color #dcdfe6
        color #606266
  .close
    position absolute
    border none
    background none
    top 4px
    right 4px
    i
      font-size 18px
</style>
