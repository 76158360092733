<template>
  <transition name="slide">
    <div class="room" @click.prevent>
      <h :isback="true">
        <span class="name" v-text="room.liveTopical" />
        <clock-in-icon />
      </h>
      <player
        ref="player"
        :poster="`http://pic-ws.doupai.live/doupai/${room.highPushUrl}.jpg`"
        :src="
          `http://hls-ws.doupai.live/doupai/${room.highPushUrl}/playlist.m3u8`
        "
      />
      <div class="info">
        <div class="top">
          <div class="avatar">
            <img :src="logo" width="35" height="35" alt="斗牌TV" />
          </div>
          <div class="text">
            <div class="name" v-text="room.liveTopical" />
            <div class="state">
              <span class="icon">直播中</span>
              <span class="count" v-text="`热度${lookCount}`" />
            </div>
          </div>
        </div>
        <div class="notice" v-text="room.roomNotice" />
      </div>
      <tab />
      <guess ref="guessList" />
      <guess-result ref="guessResult" :result="guessResult" />
      <div class="activity-pop">
        <guess-mini
          ref="guessMini"
          :finish="guessFinish"
          @clickSelf="showGuessList"
        />
        <online-reward />
        <div v-show="lotteryShowFlag || voteShowFlag" class="slide">
          <cube-slide
            ref="slideRef"
            :loop="false"
            :autoPlay="false"
            :options="{ click: true }"
            :threshold="1"
          >
            <cube-slide-item v-if="lotteryShowFlag" :key="1">
              <lottery-mini
                ref="lotteryMiniRef"
                :timer="lotteryMiniDownTimeTxt"
                @hide="lotteryMiniHide"
                @open="lotteryPlusShow"
              />
            </cube-slide-item>
            <cube-slide-item v-if="voteShowFlag" :key="2">
              <vote-mini
                ref="voteMiniRef"
                @hide="voteMiniHide"
                @open="votePlusShow"
              />
            </cube-slide-item>
          </cube-slide>
        </div>
      </div>
      <gifts-canvas />
      <clock-in-tips />
      <lottery ref="lotteryPlusRef" :timer="lotteryPlusDownTimeTxt" />
      <vote ref="votePlusRef" @done="getVoteInfo" @join="getVoteInfo" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import clockInIcon from "@/components/clock-in/icon";
import clockInTips from "@/components/clock-in/tips";
import player from "@/components/player/player";
import tab from "@/components/room/tab/tab";
import guess from "@/components/room/guess/index";
import guessResult from "@/components/room/guess/result/index";
import guessMini from "@/components/room/guess/mini/index";
import onlineReward from "@/components/room/reward/online-reward";
import lottery from "@/components/room/lottery";
import lotteryMini from "@/components/room/lottery/mini";
import vote from "@/components/room/vote";
import voteMini from "@/components/room/vote/mini";
import giftsCanvas from "@/components/room/gifts/animated/canvas";
import {
  getLiveDetails,
  getLookCount,
  getRoomGuess,
  reportRoomPlayTime,
  _checkUserInteractState,
  _getLotteryInfo,
  _getVoteInfo
} from "@/api/room";
import IM from "@/im";
import * as emoji from "@/assets/js/emoji";
import { formate } from "@/assets/js/tool";
import { QINIUYUN } from "@/api/config";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    h,
    clockInIcon,
    clockInTips,
    player,
    tab,
    guess,
    guessResult,
    guessMini,
    onlineReward,
    lottery,
    lotteryMini,
    vote,
    voteMini,
    giftsCanvas
  },
  data() {
    return {
      room: {},
      lookCount: 0,
      logo: require("@/assets/img/logo.png"),
      guessResult: {},
      guessFinish: false,
      lotteryShowFlag: false,
      voteShowFlag: false,
      lotteryMiniDownTimeTxt: "",
      lotteryPlusDownTimeTxt: ""
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      barrageList: state => state.room.roomBarrageList
    })
  },
  created() {
    this._getLiveDetails();
  },
  beforeDestroy() {
    clearTimeout(this.lotteryTimeCounter);
    clearTimeout(this.timer);
    clearTimeout(this.checkBannedTimer);
    this.roomReady(0);
    this.setRoomInfo({});
    this.setbarrage();
    this.im.loginOut();
  },
  methods: {
    ...mapMutations({
      setRoomInfo: "room/SET_ROOM_INFO",
      roomReady: "room/ROOM_READY",
      addbarrage: "room/ADD_ROOM_BARRAGE_LIST",
      setbarrage: "room/SET_ROOM_BARRAGE_LIST",
      getGiftsMsg: "room/ROOM_GIFTS_MSG",
      getDeleteMsg: "room/ROOM_DELETE_MSG",
      toast: "global/SET_TOAST",
      setLotteryInfo: "room/SET_LOTTERY_INFO",
      setVoteInfo: "room/SET_VOTE_INFO"
    }),
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    _getLiveDetails() {
      const params = {
        roomId: this.$route.params.id
      };
      getLiveDetails(params).then(res => {
        this.room = res.object;
        this.setRoomInfo(this.room);
        this.roomReady(1);
        document.title = `${this.room.liveTopical}`;
        this.webImInit();
        this.getGuessResult();
        this._reportRoomPlayTime();
        this.checkBannedStatus();
        this.getLotteryInfo();
        this.getVoteInfo();
      });
    },
    webImInit() {
      if (this.im) return;
      this.im = new IM(this.room, null, this.received);
    },
    received(event) {
      const data = event.data;
      data.forEach(item => {
        if (
          item.to == this.room.roomGroupId &&
          item.from == "@TIM#SYSTEM" &&
          item.nick == this.user.nickName
        ) {
          this.groupWel(item.payload);
          return;
        }
        if (
          item.conversationType == "GROUP" &&
          item.to == this.room.roomGroupId &&
          item.type == "TIMTextElem"
        ) {
          this.groupTextMsgToHtml(JSON.parse(item.payload.text));
          return;
        }
        const userImId = `dezhou${this.user.id}`;
        if (item.conversationType == "C2C" && item.to == userImId) {
          console.log(item);
          this.notifyTextMsgToHtml(JSON.parse(item.payload.text));
        }
      });
    },
    notifyTextMsgToHtml(msg) {
      const type = msg.type;
      switch (type) {
        case 1:
          if (msg.accountState == 2 && !msg.isPunishForever) {
            this.checkBannedStatus();
          }
          break;
      }
    },
    async checkBannedStatus() {
      if (!this.token) return;
      const res = await _checkUserInteractState();
      if (res && res.object.accountState == 1) {
        this.checkBannedTimer = null;
        return;
      }
      this.checkBannedTimer = setTimeout(() => {
        this.checkBannedStatus();
      }, 1000 * 60);
    },
    groupTextMsgToHtml(msg) {
      const type = msg.type;
      switch (type) {
        case 1:
          this.getChatInfo1(msg);
          break;
        case 2:
          this.getChatInfo2(msg);
          break;
        case 6:
          this.videoChuanLiu(msg);
          break;
        case 7:
          this.videoChuanLiu(msg);
          break;
        case 10:
          this.getLotteryInfo();
          break;
        case 11:
          clearTimeout(this.lotteryTimeCounter);
          this.$refs.lotteryMiniRef.hide();
          this.$refs.lotteryPlusRef.show();
          this.$refs.lotteryPlusRef.getLotteryResult(msg);
          this.slideRefresh();
          break;
        case 12:
          if (msg.content.split(",").indexOf(`${this.user.id}`) > -1) {
            this.getUserInfo();
          }
          break;
        case 13:
          this.getVoteInfo();
          break;
        case 15:
          this.getGuessResult(msg);
          break;
        case 16:
          this.getDeleteMsg(msg);
          break;
        case 17:
          clearTimeout(this.lotteryTimeCounter);
          if (this.token) {
            this.$createDialog({
              type: "alert",
              title: "提示",
              content:
                "因抽奖提前终止，您在抽奖期间送出的礼物已经以金币形式退还至您的账号",
              icon: "cubeic-alert"
            }).show();
            this.getUserInfo();
          }
          this.$refs.lotteryMiniRef.hide();
          this.$refs.lotteryPlusRef.hide();
          this.slideRefresh();
          break;
        case 18:
          this.addbarrage({
            ...msg,
            userId: 30
          });
          break;
        case 101:
          this.addbarrage(msg);
          break;
      }
    },
    getChatInfo1(msg) {
      const reg = /\[[em_]+[\d{1,3}]+\]+/gim;
      msg.content = msg.content.replace(/<[^>]+>/g, "");
      const emojis = emoji.emojis;
      const resouce = emoji.resource;
      msg.content = msg.content.replace(reg, match => {
        const inx = emojis.indexOf(match);
        return `<img src="${QINIUYUN}/emoji/${resouce[inx]}.png" class="emoticon" width="14" style="vertical-align: middle" >`;
      });
      if (msg.content == "") {
        return;
      }
      this.addbarrage(msg);
    },
    getChatInfo2(msg) {
      msg = {
        ...msg,
        content: `赠送了${msg.content}`
      };
      this.addbarrage(msg);
      this.getGiftsMsg(msg);
    },
    getChatInfo101(msg) {
      this.addbarrage(msg);
    },
    videoChuanLiu(msg) {
      this.room = {
        ...this.room,
        highPushUrl: msg.pushUrl,
        liveTopical: msg.liveTopical || this.room.liveTopical
      };
      this.setRoomInfo(this.room);
      this.$nextTick(() => {
        this.$refs.player.load(`
          http://hls-ws.doupai.live/doupai/${msg.pushUrl}/playlist.m3u8
        `);
        document.title = `${msg.liveTopical || this.room.liveTopical}`;
      });
    },
    groupWel(content) {
      const welId = content.operatorID.replace(/dezhou/gi, "");
      const barrageList = this.barrageList;
      if (
        this.token &&
        this.user.id == welId &&
        content.operationType == 1 &&
        barrageList.length <= 0
      ) {
        const msg = {
          avatar: this.user.avatar,
          content: `欢迎 【${this.user.nickName}】来到本直播间!`,
          nickName: "管理员",
          type: 1,
          textMsgType: 0,
          userId: 30,
          userType: this.user.userType,
          welcomeUserId: this.user.id,
          welcomeUserType: this.user.userType,
          welcomeUserNickName: this.user.nickName,
          welcomeUserRoleType: this.user.roleType,
          lv: this.user.lv,
          isSelf: true,
          honorStatus: this.user.honorStatus
        };
        this.addbarrage(msg);
      }
    },
    showGuessList() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      this.$refs.guessList.show();
    },
    readGuessResult() {
      const guess = this.$refs.guessList;
      if (guess.showFlag) {
        guess._getRoomGuess();
      }
    },
    getGuessResult(msg = {}) {
      if (!this.token) return;
      const isNotice = msg.gusessCompetition ? msg.gusessCompetition : {};
      const id = this.room.id;
      getRoomGuess(id).then(res => {
        if (res.code == 0 || res.code == 10001) {
          return;
        }
        const result = res.object;
        if (isNotice.partakeGuess) {
          result.guessComThemeList.forEach(item => {
            item.resultOption =
              item.modifyResultType > 0
                ? item.modifyResultType
                : item.resultType;
            item.resultIncome =
              item.income > 0 ? `+${item.income}` : item.income;
          });
          this.guessResult = result;
          this.$refs.guessResult.show();
        }
        if (result.guessComStatus >= 1) {
          this.$refs.guessMini.show();
        }
        if (result.guessComStatus > 1) {
          this.guessFinish = true;
        } else {
          this.guessFinish = false;
        }
        this.readGuessResult();
      });
    },
    async _getLookCount() {
      const id = this.room.id;
      const res = await getLookCount(id);
      if (res) {
        this.lookCount = res.object;
      }
    },
    async _reportRoomPlayTime() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const params = {
        roomId: this.room.id
      };
      this._getLookCount();
      await reportRoomPlayTime(params);
      this.timer = setTimeout(() => {
        this._reportRoomPlayTime();
        this.timer = null;
      }, 180000);
    },
    lotteryPlusShow() {
      this.$refs.lotteryPlusRef.show();
      this.$refs.lotteryPlusRef.showPlus();
    },
    lotteryMiniHide() {
      this.lotteryShowFlag = false;
    },
    slideRefresh() {
      this.$nextTick(() => {
        this.$refs.slideRef.refresh();
      });
    },
    async getLotteryInfo() {
      const params = {
        liveId: this.room.id
      };
      const res = await _getLotteryInfo(params);
      if (res) {
        const data = res.object;
        if (data.lotteryDrawStatus == 1) {
          this.lotteryShowFlag = true;
          this.$nextTick(() => {
            this.$refs.lotteryMiniRef.show();
            const nowTime = new Date().getTime();
            const ms = parseInt(data.restofLotteryDrawTimeMills);
            this.lotteryCountTime(ms, nowTime);
            this.$refs.slideRef.refresh();
          });
          this.setLotteryInfo(data);
        }
      }
    },
    lotteryCountTime(ms, nowTime) {
      /** 抽奖倒计时函数 */
      if (this.lotteryTimeCounter) {
        clearTimeout(this.lotteryTimeCounter);
      }
      const interval = 10;
      let count = 0;
      const countDownStart = () => {
        count++;
        const offset = new Date().getTime() - (nowTime + count * interval);
        let nextTime = interval - offset;
        if (nextTime < 0) {
          nextTime = 0;
        }
        ms -= interval;
        const m = formate(parseInt(ms / 1000 / 60));
        const s = formate(parseInt((ms / 1000) % 60));
        let msl = parseInt((ms % 1000) / 10);
        if (msl < 10) {
          msl = "0" + msl;
        }
        this.lotteryMiniDownTimeTxt = `${m}:${s}`;
        this.lotteryPlusDownTimeTxt = `${m}:${s}:${msl}`;
        if (ms < 0) {
          clearTimeout(this.lotteryTimeCounter);
        } else {
          this.lotteryTimeCounter = setTimeout(countDownStart, nextTime);
        }
      };
      if (ms >= 0) {
        this.lotteryTimeCounter = setTimeout(countDownStart, interval);
      }
    },
    votePlusShow() {
      this.$refs.votePlusRef.show();
    },
    voteMiniHide() {
      this.voteShowFlag = false;
    },
    async getVoteInfo() {
      if (this.configLoading) return;
      this.configLoading = true;
      const params = {
        liveId: this.room.id
      };
      const res = await _getVoteInfo(params);
      this.configLoading = false;
      if (res.code == 1) {
        const data = res.object;
        this.voteShowFlag = true;
        this.$nextTick(() => {
          this.$refs.voteMiniRef.show();
          this.$refs.voteMiniRef.status == data.voteStatus;
          this.$refs.slideRef.refresh();
        });
        this.setVoteInfo(data);
      } else {
        this.$refs.votePlusRef.hide();
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.room
  position fixed
  width 100%
  height 100%
  display flex
  flex-direction column
  top 0
  left 0
  background #F2F5F6
  z-index 10
  transform translateZ(0)
  .info
    background #fff
    padding 7px 6px 8px 11px
    .top
      display flex
      .avatar
        font-size 0
      .text
        margin-left 6px
        .name
          font-weight bold
          font-size 14px
          color #383A40
        .state
          vertical-align middle
          font-size 10px
          .icon
            display inline-block
            padding 1px 3px
            background #1E88E4
            border-radius 2px
            color #fff
            text-align center
          .count
            color #383A40
    .notice
      font-size 10px
      color #6B6B6B
  .activity-pop
    position absolute
    right 11px
    bottom 96px
    width 54px
    text-align center
    z-index 11
    .slide
      width 100%
      height 59px
</style>
