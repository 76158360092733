<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="gift-pop" @click.self="hide">
      <div v-if="list.length" class="content">
        <ul class="list">
          <li
            v-for="(item, inx) in list"
            :key="item.id"
            class="item"
            :class="{ light: inx == selectItemInx }"
            @click.stop="selectItem(inx)"
          >
            <div class="icon">
              <img :src="pngList[inx]" alt="礼物图标" />
            </div>
            <div class="name">{{ item.giftName }}</div>
            <div class="price">{{ item.giftPrice }}</div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <div class="l" @click.stop="transGold">
          金币：{{ user.gold }}<i class="cubeic-arrow" />
        </div>
        <div class="r">
          <button
            class="btn"
            :class="{ ban: selectItemInx < 0 }"
            @click.stop="_giveGifts()"
          >
            赠送
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { getGiftList, giveGifts, _joinLottery } from "@/api/room";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      showFlag: false,
      list: [],
      pngList: [
        require("./img/8.png"),
        require("./img/7.png"),
        require("./img/6.png"),
        require("./img/5.png"),
        require("./img/4.png"),
        require("./img/3.png"),
        require("./img/2.png"),
        require("./img/1.png")
      ],
      selectItemInx: -1
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      room: state => state.room.roomInfo,
      lotteryInfo: state => state.room.lotteryInfo
    })
  },
  methods: {
    ...mapMutations({
      setUserInfo: "user/SET_INFO",
      toast: "global/SET_TOAST",
      setLotteryHistory: "room/SET_JOIN_LOTTERY_HISTORY"
    }),
    show() {
      if (!this.list.length) {
        this._getGiftList();
      }
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    async _getGiftList() {
      const res = await getGiftList();
      if (!res) return;
      this.list = res.object;
    },
    selectItem(inx) {
      this.selectItemInx = inx;
    },
    async _giveGifts() {
      if (!this.token) return;
      if (this.selectItemInx < 0) {
        this.toast("请选择您要赠送的礼物!");
        return;
      }
      const gift = this.list[this.selectItemInx];
      if (this.user.gold < gift.giftPrice) {
        this.toast("您的金币余额不足!");
        return;
      }
      if (this.giveLoading) return;
      this.giveLoading = true;
      const params = {
        giftId: gift.id,
        liveId: this.room.id,
        num: 1
      };
      const res = await giveGifts(params);
      this.giveLoading = false;
      if (!res) return;
      this.setUserInfo({
        ...this.user,
        gold: this.user.gold - gift.giftPrice
      });
      if (this.lotteryInfo.giftId == gift.id) {
        this.joinLottery();
      }
    },
    async joinLottery() {
      const params = {
        liveId: this.room.id
      };
      const res = await _joinLottery(params);
      if (res) {
        this.setLotteryHistory(this.lotteryInfo.id);
        this.$createToast({
          txt: "参与抽奖成功，祝你好运",
          type: "correct"
        }).show();
      }
    },
    transGold() {
      this.$createDialog({
        type: "confirm",
        icon: "cubeic-alert",
        title: "提示",
        content: "是否前往兑换金币?",
        confirmBtn: {
          text: "确定",
          active: true,
          disabled: false,
          href: "javascript:;"
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;"
        },
        onConfirm: () => {
          this.$router.push("/member/dpp");
        }
      }).show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.gift-pop
  position fixed
  top 36px
  right 0
  bottom 0
  left 0
  z-index 12
  .content
    position absolute
    right 0
    bottom 45px
    left 0
    background rgba(255, 255, 255, 1)
    .list
      display flex
      flex-wrap wrap
      .item
        flex 0 0 25%
        padding 8px 0
        border 1px solid transparent
        text-align center
        &.light
          border-color #F49756
          color #F49756
        .icon
          padding-bottom 4px
          font-size 0
          img
            width 50px
            height 50px
        .name
          font-size 11px
        .price
          font-size 10px
  .bottom
    position absolute
    height 45px
    right 0
    bottom 0
    left 0
    display flex
    justify-content space-between
    align-items center
    padding 0 17px 0 20px
    background #fff
    .l
      font-size 10px
      color #050505
    .r
      .btn
        width 75px
        height 23px
        line-height 23px
        padding 0
        background #007AFF
        border none
        border-radius 3px
        text-align center
        font-size 12px
        color #fff
        &.ban
          background #bfbfbf
</style>
