<template>
  <div v-if="showFlag" class="room-guess-result">
    <div class="content">
      <h2 class="title">
        <span class="text">竞猜结果</span>
        <i class="cubeic-close" @click="hide" />
      </h2>
      <div class="tips">
        获得的DPP将直接发放到你的账户。
      </div>
      <ul class="list">
        <li
          v-for="(item, inx) in result.guessComThemeList"
          :key="inx"
          class="item"
        >
          <div class="theme" v-text="item.guessTheme" />
          <div class="win">
            胜利方：<span
              v-text="
                item.resultOption == 1
                  ? item.itemOneContent
                  : item.resultOption == 2
                  ? item.itemTwoContent
                  : item.resultOption == 3
                  ? '流局'
                  : ''
              "
            />
          </div>
          <div class="state profit" v-text="item.resultIncome" />
        </li>
      </ul>
      <div class="bottom">
        <div class="btn light" @click="toLink('/member/guess')">竞猜详情</div>
        <div class="btn" @click="hide">知道了</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    result: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.$emit("done");
      this.showFlag = false;
    },
    toLink(link) {
      this.$router.push(link);
    }
  }
};
</script>
<style lang="stylus" scoped>
.room-guess-result
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .6)
  z-index 99
  .content
    position absolute
    top 50%
    left 50%
    width 324px
    background #fff
    border-radius 15px
    transform translate3d(-50%, -50%, 0)
    overflow hidden
    .title
      position relative
      line-height 40px
      border-bottom 1px dashed #383A40
      text-align center
      .text
        font-size 17px
        font-weight bold
        color #383A40
      i
        position absolute
        top 14px
        right 14px
        line-height 12px
        font-size 12px
        font-weight bold
        color #AAABB2
    .tips
      margin 13px 23px 7px
      font-size 13px
      color #6B6B6B
    .list
      margin 0 23px 14px
      .item
        position relative
        width 100%
        height 53px
        margin-bottom 7px
        padding 8px 5px
        background linear-gradient(to right, #1D7EFF, #3ECEFF)
        border-radius 3px
        color #fff
        &:last-child
          margin-bottom 0
        .theme
          line-height 14px
          font-size 14px
          font-weight bold
          margin-bottom 10px
          border-left 3px solid #fff
          padding-left 10px
        .win
          font-size 14px
        .state
          position absolute
          right 5px
          bottom 8px
          width 72px
          height 22px
          line-height 18px
          background #E8E8E8
          border 2px solid #666666
          border-radius 3px
          text-align center
          font-size 14px
          color #676767
          &.profit
            border-color #E6583B
            color #E6583B
            background #fff
          &.lost
            border-color #1E80FF
            color #1E80FF
            background #fff
    .bottom
      margin-bottom 17px
      text-align center
      .btn
        display inline-block
        width 93px
        height 34px
        line-height 32px
        border 2px solid #BFBFBF
        border-radius 17px
        font-size 15px
        color #BFBFBF
        &.light
          margin-right 11px
          border-color #2DA9FF
          color #2DA9FF
</style>
