import { render, staticRenderFns } from "./online-reward.vue?vue&type=template&id=5d967c79&scoped=true&"
import script from "./online-reward.vue?vue&type=script&lang=js&"
export * from "./online-reward.vue?vue&type=script&lang=js&"
import style0 from "./online-reward.vue?vue&type=style&index=0&id=5d967c79&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d967c79",
  null
  
)

export default component.exports