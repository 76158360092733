export const emojis = [
  "[em_0]",
  "[em_1]",
  "[em_2]",
  "[em_3]",
  "[em_4]",
  "[em_5]",
  "[em_6]",
  "[em_7]",
  "[em_8]",
  "[em_9]",
  "[em_10]",
  "[em_11]",
  "[em_12]",
  "[em_13]",
  "[em_14]",
  "[em_15]",
  "[em_16]",
  "[em_17]",
  "[em_18]",
  "[em_19]",
  "[em_20]",
  "[em_21]",
  "[em_22]",
  "[em_23]",
  "[em_24]",
  "[em_25]",
  "[em_26]",
  "[em_27]",
  "[em_28]",
  "[em_29]",
  "[em_30]",
  "[em_31]",
  "[em_32]",
  "[em_33]",
  "[em_34]",
  "[em_35]",
  "[em_36]",
  "[em_37]",
  "[em_38]",
  "[em_39]",
  "[em_40]",
  "[em_41]",
  "[em_42]",
  "[em_43]",
  "[em_44]",
  "[em_45]",
  "[em_46]",
  "[em_47]",
  "[em_48]",
  "[em_49]",
  "[em_50]",
  "[em_51]",
  "[em_52]",
  "[em_53]",
  "[em_54]",
  "[em_55]",
  "[em_56]",
  "[em_57]",
  "[em_58]",
  "[em_59]",
  "[em_60]",
  "[em_61]",
  "[em_62]",
  "[em_63]",
  "[em_64]",
  "[em_65]",
  "[em_66]",
  "[em_67]",
  "[em_68]",
  "[em_69]",
  "[em_70]",
  "[em_71]",
  "[em_72]",
  "[em_73]",
  "[em_74]",
  "[em_75]",
  "[em_76]",
  "[em_77]",
  "[em_78]",
  "[em_79]",
  "[em_80]",
  "[em_81]",
  "[em_82]",
  "[em_83]",
  "[em_84]",
  "[em_85]",
  "[em_86]",
  "[em_87]",
  "[em_88]",
  "[em_89]",
  "[em_90]",
  "[em_91]",
  "[em_92]",
  "[em_93]",
  "[em_94]",
  "[em_95]",
  "[em_96]",
  "[em_97]",
  "[em_98]",
  "[em_99]",
  "[em_100]",
  "[em_101]",
  "[em_102]",
  "[em_103]",
  "[em_104]",
  "[em_105]",
  "[em_106]",
  "[em_107]",
  "[em_108]",
  "[em_109]",
  "[em_110]",
  "[em_111]",
  "[em_112]",
  "[em_113]",
  "[em_114]",
  "[em_115]",
  "[em_116]",
  "[em_117]",
  "[em_118]",
  "[em_119]",
  "[em_120]",
  "[em_121]",
  "[em_122]",
  "[em_123]",
  "[em_124]",
  "[em_125]",
  "[em_126]",
  "[em_127]"
];
export const resource = [
  "u1f603",
  "u1f600",
  "u1f60a",
  "u263a",
  "u1f609",
  "u1f60d",
  "u1f618",
  "u1f61a",
  "u1f61c",
  "u1f61d",
  "u1f633",
  "u1f601",
  "u1f614",
  "u1f60c",
  "u1f612",
  "u1f61f",
  "u1f61e",
  "u1f623",
  "u1f622",
  "u1f602",
  "u1f62d",
  "u1f62a",
  "u1f630",
  "u1f605",
  "u1f613",
  "u1f62b",
  "u1f629",
  "u1f628",
  "u1f631",
  "u1f621",
  "u1f624",
  "u1f616",
  "u1f606",
  "u1f60b",
  "u1f637",
  "u1f60e",
  "u1f634",
  "u1f632",
  "u1f635",
  "u1f608",
  "u1f47f",
  "u1f62f",
  "u1f62c",
  "u1f615",
  "u1f636",
  "u1f607",
  "u1f60f",
  "u1f611",
  "u1f648",
  "u1f649",
  "u1f64a",
  "u1f47d",
  "u1f4a9",
  "u1f494",
  "u1f525",
  "u1f4a2",
  "u1f4a4",
  "u1f6ab",
  "u2b50",
  "u26a1",
  "u1f319",
  "u2600",
  "u26c5",
  "u2601",
  "u2744",
  "u2614",
  "u26c4",
  "u1f44d",
  "u1f44e",
  "u1f44c",
  "u1f44a",
  "u270a",
  "u270c",
  "u270b",
  "u1f64f",
  "u261d",
  "u1f44f",
  "u1f4aa",
  "u1f46a",
  "u1f46b",
  "u1f47c",
  "u1f434",
  "u1f436",
  "u1f437",
  "u1f47b",
  "u1f339",
  "u1f33b",
  "u1f332",
  "u1f384",
  "u1f381",
  "u1f389",
  "u1f4b0",
  "u1f382",
  "u1f356",
  "u1f35a",
  "u1f366",
  "u1f36b",
  "u1f349",
  "u1f377",
  "u1f37b",
  "u2615",
  "u1f3c0",
  "u26bd",
  "u1f3c2",
  "u1f3a4",
  "u1f3b5",
  "u1f3b2",
  "u1f004",
  "u1f451",
  "u1f484",
  "u1f48b",
  "u1f48d",
  "u1f4da",
  "u1f393",
  "u270f",
  "u1f3e1",
  "u1f6bf",
  "u1f4a1",
  "u1f4de",
  "u1f4e2",
  "u1f556",
  "u23f0",
  "u23f3",
  "u1f4a3",
  "u1f52b",
  "u1f48a",
  "u1f680",
  "u1f30f"
];
