<template>
  <div class="loop-pie">
    <div class="loop-pie-line loop-pie-r">
      <div
        class="loop-pie-c loop-pie-rm"
        id="loop-rc"
        :style="
          `transform: rotate(${rotateRight}deg); border-bottom-color: ${color}; border-right-color: ${color}`
        "
      />
    </div>
    <div class="loop-pie-line loop-pie-l">
      <div
        class="loop-pie-c loop-pie-lm"
        id="loop-lc"
        :style="
          `transform: rotate(${rotateLeft}deg); border-bottom-color: ${color}; border-right-color: ${color}`
        "
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "#1B66DD"
    }
  },
  computed: {
    rotate() {
      return this.percent * 360;
    },
    rotateLeft() {
      return this.rotate < 180 ? -45 : this.rotate - 180 - 45;
    },
    rotateRight() {
      return this.rotate < 180 ? this.rotate + -45 : 135;
    }
  }
};
</script>
<style lang="stylus" scoped>
.loop-pie {
	position:relative;
	width:100%;
	height:100%;
}
.loop-pie-line {
	position:absolute;
	width:50%;
	height:100%;
	top:0;
	overflow:hidden;
}
.loop-pie-l {
	top:0px;
	left:0px;
}
.loop-pie-r {
	top:0px;
	-webkit-transform:rotate(180deg);
	right:0px
}
.loop-pie-c {
	width:200%;
	height:100%;
	border:4px solid transparent;
	border-radius:50%;
	position:absolute;
	box-sizing:border-box;
	top:0;
	-webkit-transform:rotate(-45deg);
}
.loop-pie-rm, .loop-pie-lm {
	border-top:4px solid #C2C4C5;
	border-left:4px solid #C2C4C5;
	border-bottom:4px solid #1ac4c7;
	border-right:4px solid #1ac4c7;
}
</style>
