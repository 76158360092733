<template>
  <div v-if="showFlag" class="mini" @click.stop="open">
    <img class="logo" src="./img/logo.png" alt="logo" />
    <p class="name">口令抽奖</p>
    <p class="timer">{{ timer }}</p>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    timer: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showFlag: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  methods: {
    show() {
      this.$emit("show");
      this.showFlag = true;
    },
    hide() {
      this.$emit("hide");
      this.showFlag = false;
    },
    open() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      this.$emit("open");
    },
    done() {
      this.hide();
      this.$emit("done");
    }
  }
};
</script>
<style lang="stylus" scoped>
.mini
  position relative
  padding 2px 2px 12px
  margin-bottom 8px
  background #fff
  border 0px solid #CFCFCF
  border-radius 3px
  box-shadow 0px 1px 3px 0px rgba(0, 0, 0, 0.1)
  .logo
    width 40px
    height 34px
  .name
    position absolute
    right 0
    bottom 2px
    left 0
    font-size 10px
    color #000
    transform scale(.8)
  .timer
    position absolute
    top 24px
    right 0
    left 0
    font-size 10px
    font-weight bold
    color #fff
</style>
