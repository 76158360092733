<template>
  <div
    v-show="showFlag && token"
    class="ornament-box"
    :class="{ focus: iosFocus, itemshow: activeInx > 0 }"
    @click.self="hide"
  >
    <div class="content" @click.stop>
      <ul class="list border-top-1px border-bottom-1px">
        <li
          class="item"
          :class="{ active: activeInx == 1 }"
          @click.stop="showDanmuColorWin"
        >
          <div class="danmu">弹</div>
        </li>
        <li
          class="item"
          :class="{ active: activeInx == 2 }"
          @click.stop="showKingWin"
        >
          <div class="king">
            <img :src="activeInx == 2 ? king[1] : king[0]" alt="斗牌之星" />
          </div>
        </li>
        <li
          class="item"
          :class="{ active: activeInx == 3 }"
          @click.stop="showFansWin"
        >
          <div class="fans">粉</div>
        </li>
      </ul>
      <danmu-color v-show="activeInx == 1" />
      <doupai-star v-show="activeInx == 2" />
      <fans-tag-box v-show="activeInx == 3" />
    </div>
  </div>
</template>
<script>
import danmuColor from "@/components/room/ornament/danmu-color";
import doupaiStar from "@/components/room/ornament/doupai-star";
import fansTagBox from "@/components/room/ornament/fans-tag";
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    iosFocus: {
      type: Boolean,
      default: false
    }
  },
  components: {
    danmuColor,
    doupaiStar,
    fansTagBox
  },
  data() {
    return {
      showFlag: true,
      king: [require("./img/king.png"), require("./img/king-sel.png")],
      activeInx: 0
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  watch: {
    isFocus(flag) {
      if (flag) {
        this.show();
      }
    }
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    show() {
      this.showFlag = true;
    },
    hide() {
      // this.showFlag = false;
      this.activeInx = 0;
    },
    showDanmuColorWin() {
      this.activeInx = 1;
    },
    showKingWin() {
      this.activeInx = 2;
    },
    showFansWin() {
      this.activeInx = 3;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.ornament-box
  position fixed
  right 0
  bottom 50px
  left 0
  transform translateZ(0)
  z-index 9
  &.focus
    bottom 80px
    z-index 12
  &.itemshow
    position fixed
    top 36px
    z-index 12
  .content
    position absolute
    right 0
    bottom 0
    left 0
  .list
    position relative
    display flex
    padding 8px 21px
    background #fff
    z-index 1
    .item
      margin-right 19px
      &:last-child
        margin-right 0
      &.active
        .danmu,.fans
          background-color #FF8727
      .danmu,.fans
        width 18px
        height 18px
        line-height 18px
        background #CECECE
        border-radius 50%
        text-align center
        font-size 10px
        color #fff
      .king
        img
          width 18px
      .fans
        border-radius 3px
</style>
