<template>
  <div v-if="showFlag" class="guess-mini" @click="clickSelf">
    <img v-show="!finish" src="./img/guess.png" class="icon" />
    <img v-show="finish" src="./img/end.png" class="icon" />
    <!-- <span v-show="finish" class="text">已结束</span> -->
  </div>
</template>
<script>
export default {
  props: {
    finish: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    clickSelf() {
      this.$emit("clickSelf");
    }
  }
};
</script>
<style lang="stylus" scoped>
.guess-mini
  position relative
  display inline-block
  margin-bottom 8px
  // padding 5px
  // background #fff
  // border-radius 3px
  .icon
    width 48px
  .text
    position absolute
    left 50%
    margin-left -18px
    bottom -7px
    display inline-block
    width 36px
    height 14px
    line-height 14px
    background linear-gradient(270deg, #E76525, #FF9249)
    border-radius 7px
    font-size 11px
    color #fff
    text-align center
</style>
