/**
 * 获取当前时间戳
 */
export const now =
  Date.now ||
  function() {
    return new Date().getTime();
  };
/**
 * 创建并返回一个像节流阀一样的函数
 * @param {Funtion} func 传入的函数
 * @param {Number} wait 时间间隔
 * @param {leading: false || trailing: false} options
 */
export function throttle(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;
  if (!options) options = {};
  const later = function() {
    previous = options.leading === false ? 0 : now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    if (!previous && options.leading === false) previous = now();
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}
/**
 * 将数组分为若干份
 * @param {Array} arr 被分割的数组
 * @param {Number} len 分割量
 */
export function group(arr, len) {
  let index = 0;
  const newArray = [];
  while (index < arr.length) {
    newArray.push(arr.slice(index, (index += len)));
  }
  return newArray;
}
/**
 * 函数防抖
 * @param {Function} func 返回 function 函数的防反跳版本,
 * @param {Number} wait 将延迟函数的执行(真正的执行)在函数最后一次调用时刻的 wait 毫秒之后.
 * @param {Boolean} immediate 为 true， debounce会在 wait 时间间隔的开始调用这个函数
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;
  const later = function() {
    const last = now() - timestamp;
    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function() {
    context = this;
    args = arguments;
    timestamp = now();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
/** 给输入参数补0 */
export function formate(time) {
  if (time >= 10) {
    return time;
  } else {
    return `0${time}`;
  }
}
