<template>
  <div v-if="showFlag" class="vote">
    <transition name="slide-in">
      <div v-show="mode == 2" class="plus" @click.self="changeMode(1)">
        <div class="content">
          <div class="title">
            <span>{{ config.voteTheme }}</span>
            <button class="close" @click.stop="changeMode(1)">
              <i class="cubeic-close" />
            </button>
          </div>
          <div class="scroll-wrap">
            <div v-if="config.voteStatus == 0" class="not-data">
              <p>
                <img class="ticket" src="./img/ticket.png" alt="票图标" />
              </p>
              <p class="tips">投票暂未开始，敬请期待！</p>
              <p class="timer">
                距投票开始还有<span>{{ downTimeTxt }}</span>
              </p>
            </div>
            <div
              v-if="config.voteType == 0"
              class="candidate vote-text-type"
              :class="{ end: config.voteStatus == 2 }"
            >
              <cube-scroll :options="{ click: true, bounce: false }">
                <p v-if="config.voteStatus == 1" class="end-timer">
                  距投票结束还有<span>{{ downTimeTxt }}</span>
                </p>
                <ul>
                  <li v-for="(item, inx) in list" :key="item.id">
                    <div class="l">
                      <p class="name">{{ item.voteText }}</p>
                      <div class="press">
                        <div
                          class="bar"
                          :style="
                            `width: ${getPercent(item.voteCount, total) * 100}%`
                          "
                        />
                        <span class="count">{{ item.voteCount }}票</span>
                      </div>
                    </div>
                    <div v-if="config.voteStatus == 1" class="btn">
                      <button
                        v-if="config.userVoteChoiceItemId == item.id"
                        class="ban"
                      >
                        {{ item.downTimeTxt }}
                      </button>
                      <button
                        v-else
                        :class="{ disable: config.userVoteTimeMills > 0 }"
                        @click.stop="joinVote(item)"
                      >
                        投票
                      </button>
                    </div>
                    <div v-if="config.voteStatus == 2" class="btn">
                      <button>第{{ inx + 1 }}名</button>
                    </div>
                  </li>
                </ul>
              </cube-scroll>
            </div>
            <div
              v-if="config.voteType == 1"
              class="candidate vote-image-type"
              :class="{ end: config.voteStatus == 2 }"
            >
              <cube-scroll :options="{ click: true, bounce: false }">
                <p v-if="config.voteStatus == 1" class="end-timer">
                  距投票结束还有<span>{{ downTimeTxt }}</span>
                </p>
                <ul>
                  <li v-for="(item, inx) in list" :key="item.id">
                    <p v-if="config.voteStatus == 2" class="number">
                      第{{ inx + 1 }}名
                    </p>
                    <div class="avatar">
                      <loop
                        v-if="config.voteStatus == 2"
                        :percent="getPercent(item.voteCount, total)"
                        :color="inx < 3 ? topBarColor[inx] : '#1B66DD'"
                      />
                      <img class="image" :src="item.voteImg" alt="头像" />
                      <img
                        v-if="config.voteStatus == 2"
                        class="king"
                        :src="topImage[inx]"
                        alt="名次装饰"
                      />
                    </div>
                    <p class="name">{{ item.voteText }}</p>
                    <p class="count">{{ item.voteCount }}票</p>
                    <button
                      v-if="config.voteStatus == 1"
                      class="btn"
                      :class="{
                        ban: config.userVoteChoiceItemId == item.id,
                        disable: config.userVoteTimeMills > 0
                      }"
                      @click.stop="joinVote(item)"
                    >
                      {{
                        config.userVoteChoiceItemId == item.id
                          ? item.downTimeTxt
                          : "投票"
                      }}
                    </button>
                  </li>
                </ul>
              </cube-scroll>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import loop from "./loop";
import { avatar } from "@/api/config";
import { formate } from "@/assets/js/tool";
import { mapState, mapActions } from "vuex";
import { _joinVote } from "@/api/room";
export default {
  components: {
    loop
  },
  data() {
    return {
      showFlag: false,
      mode: 0, //1-mini, 2-plus
      avatar,
      topImage: [
        require("./img/top1.png"),
        require("./img/top2.png"),
        require("./img/top3.png")
      ],
      topBarColor: ["#F7BF53", "#88A2C4", "#D28C6A"],
      hasVote: true,
      config: { voteType: -1 }, //type:0文字，1文字+图片
      list: [],
      total: 0,
      downTimeTxt: ""
    };
  },
  computed: {
    ...mapState({
      room: state => state.room.roomInfo,
      ready: state => state.room.roomReady,
      voteInfo: state => state.room.voteInfo
    })
  },
  watch: {
    voteInfo(info) {
      this.setInfo(info);
    }
  },
  destroyed() {
    clearTimeout(this.timeCounter);
    clearTimeout(this.choiceTimer);
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    show() {
      this.showFlag = true;
      this.changeMode(2);
      this.setInfo(this.voteInfo);
    },
    hide() {
      this.showFlag = false;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    setInfo(data) {
      clearTimeout(this.timeCounter);
      clearTimeout(this.choiceTimer);
      this.config = data;
      if (data.voteStatus == 0) {
        const nowTime = new Date().getTime();
        this.countTime(data.voteStartTimeMills - nowTime);
      } else {
        if (!data.voteChoiceList) {
          return;
        }
        this.list = this.config.voteChoiceList;
        this.countTotal();
        if (data.restofVoteTimeMills <= 0) {
          return;
        }
        this.countTime(data.restofVoteTimeMills + 1000);
        this.list.forEach((choice, index) => {
          if (choice.userVoteTimeMills > 0) {
            const ms =
              choice.userVoteTimeMills +
              data.voteInterval * 60 * 1000 -
              data.systemCurrTimeMills;
            const h = formate(parseInt((ms / 1000 / 60 / 60) % 24));
            const m = formate(parseInt((ms / 1000 / 60) % 60));
            const s = formate(parseInt((ms / 1000) % 60));
            choice.downTimeTxt = `${h}:${m}:${s}`;
            this.choiceTime(ms, index);
          }
        });
      }
    },
    getPercent(x, y) {
      return x > 0 ? x / y : 0;
    },
    countTotal() {
      this.total = 0;
      this.list.forEach(item => {
        this.total += item.voteCount;
      });
    },
    done() {
      this.$emit("done");
    },
    countTime(ms) {
      if (this.timeCounter) {
        clearTimeout(this.timeCounter);
      }
      const nowTime = new Date().getTime();
      const interval = 10;
      let count = 0;
      const countDownStart = () => {
        count++;
        const offset = new Date().getTime() - (nowTime + count * interval);
        let nextTime = interval - offset;
        if (nextTime < 0) {
          nextTime = 0;
        }
        ms -= interval;
        let d = parseInt(ms / 1000 / 60 / 60 / 24);
        d = d > 0 ? `${d}天` : "";
        const h = formate(parseInt((ms / 1000 / 60 / 60) % 24));
        const m = formate(parseInt((ms / 1000 / 60) % 60));
        const s = formate(parseInt((ms / 1000) % 60));
        this.downTimeTxt = `${d}${h}:${m}:${s}`;
        if (ms <= 0) {
          clearTimeout(this.timeCounter);
          this.done();
        } else {
          this.timeCounter = setTimeout(countDownStart, nextTime);
        }
      };
      if (ms >= 0) {
        this.timeCounter = setTimeout(countDownStart, interval);
      }
    },
    choiceTime(ms, choice) {
      if (this.choiceTimer) {
        clearTimeout(this.choiceTimer);
      }
      const nowTime = new Date().getTime();
      const interval = 10;
      let count = 0;
      const countDownStart = () => {
        count++;
        const offset = new Date().getTime() - (nowTime + count * interval);
        let nextTime = interval - offset;
        if (nextTime < 0) {
          nextTime = 0;
        }
        ms -= interval;
        const h = formate(parseInt((ms / 1000 / 60 / 60) % 24));
        const m = formate(parseInt((ms / 1000 / 60) % 60));
        const s = formate(parseInt((ms / 1000) % 60));
        this.list[choice].downTimeTxt = `${h}:${m}:${s}`;
        if (ms <= 0) {
          clearTimeout(this.choiceTimer);
          this.done();
        } else {
          this.choiceTimer = setTimeout(countDownStart, nextTime);
        }
      };
      if (ms >= 0) {
        this.choiceTimer = setTimeout(countDownStart, interval);
      }
    },
    async joinVote(item) {
      if (this.config.userVoteTimeMills > 0 || this.config.voteStatus != 1) {
        return;
      }
      if (this.partakeLoding) return;
      this.partakeLoding = true;
      const params = {
        liveId: this.room.id,
        choiceVoteItemIds: item.id
      };
      const res = await _joinVote(params);
      this.partakeLoding = false;
      if (res) {
        if (res.object.prizeMsg) {
          this.$createDialog({
            type: "alert",
            title: "提示",
            content: res.object.prizeMsg,
            icon: "cubeic-alert"
          }).show();
          this.getUserInfo();
        }
        this.$emit("join");
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.vote
  position relative
  .mini
    position relative
    padding 2px
    background #fff
    border-radius 3px
    .icon
      width 36px
    .end
      position absolute
      bottom -7px
      left 50%
      width 36px
      margin-left -18px
  .plus
    position fixed
    top 36px
    right 0
    bottom 0
    left 0
    z-index 12
    .content
      position absolute
      top calc(100vw / (16 / 9))
      right 0
      bottom 0
      left 0
      display flex
      flex-direction column
      background #F2F5F6
      text-align center
      .title
        position relative
        padding 6px 0
        background #fff
        span
          font-size 17px
        .close
          position absolute
          top 10px
          right 13px
          background transparent
          padding 0
          border none
          font-size 0
          i
            font-size 11px
            font-weight bold
            color #AAABB2
      .scroll-wrap
        position relative
        flex 1
        .not-data
          padding-top 110px
          .ticket
            width 57px
            padding-bottom 18px
          .tips
            padding-bottom 12px
            font-size 13px
            color #1E88E4
          .timer
            font-size 17px
            color #000
            span
              margin-left 5px
              color #38CB4D
        .candidate
          position absolute
          top 0
          right 0
          bottom 0
          left 0
        .vote-text-type
          padding 17px 10px 0 15px
          &.end
            ul
              li
                &:nth-of-type(1)
                  .l
                    .press
                      .bar
                        background linear-gradient(-55deg, #E5960C, #FEBF01)
                  .btn
                    button
                      background linear-gradient(-55deg, #E5960C, #FEBF01)
                      color #fff
                      border 0
                      transform skew(-18deg)
                &:nth-of-type(2)
                  .l
                    .press
                      .bar
                        background linear-gradient(-55deg, #82A2D6, #A1B9E1)
                  .btn
                    button
                      background linear-gradient(-55deg, #82A2D6, #A1B9E1)
                      color #fff
                      border 0
                      transform skew(-18deg)
                &:nth-of-type(3)
                  .l
                    .press
                      .bar
                        background linear-gradient(-55deg, #D39E77, #E8BC9B)
                  .btn
                    button
                      background linear-gradient(-55deg, #D39E77, #E8BC9B)
                      color #fff
                      border 0
                      transform skew(-18deg)
                .btn
                  button
                    width 77px
                    height 18px
                    line-height 18px
                    background none
                    border-radius 0
                    border 0
                    font-size 17px
                    color #1E88E4
          .end-timer
            padding 6px 0
            font-size 11px
            color #6B6B6B
            span
              color #38CB4D
          ul
            padding 0 3px
            li
              display flex
              justify-content space-between
              align-items end
              padding-bottom 14px
              .l
                text-align left
                .name
                  padding-bottom 4px
                  font-size 16px
                  font-weight bold
                  color #383A40
                .press
                  position relative
                  width 258px
                  height 18px
                  line-height 18px
                  background #D9DCDD
                  transform skew(-18deg)
                  .bar
                    position absolute
                    top 0
                    bottom 0
                    left 0
                    width 100%
                    background linear-gradient(-55deg, #1A62DB, #2A9BFB)
                  .count
                    display block
                    padding-left 12px
                    font-size 13px
                    color #383A40
                    transform skew(18deg)
              .btn
                button
                  width 80px
                  height 30px
                  line-height 30px
                  padding 0
                  border none
                  border-radius 15px
                  background linear-gradient(-55deg, #1A62DB, #2A9BFB)
                  font-size 16px
                  color #fff
                  &.ban
                    background #fff
                    border 1px solid #1E88E4
                    color #1E88E4
                  &.disable
                    background linear-gradient(to bottom right, #5b5b5b, #828282)
        .vote-image-type
          &.end
            ul
              padding-top 33px
          .end-timer
            padding 6px 0
            font-size 11px
            color #6B6B6B
            span
              color #38CB4D
          ul
            display flex
            flex-wrap wrap
            justify-content space-between
            padding 0 28px
            li
              padding-bottom 26px
              .number
                padding-bottom 5px
                font-size 16px
                color #1E88E4
              .avatar
                position relative
                width 84px
                height 84px
                .image
                  position absolute
                  top 4px
                  left 4px
                  width 76px
                  height 76px
                  border-radius 50%
                .king
                  position absolute
                  left 50%
                  bottom 0
                  width 32px
                  margin-left -16px
              .name
                padding 6px 0
                font-size 16px
                color #383A40
              .count
                padding-bottom 8px
                font-size 13px
                color #383A40
              .btn
                width 80px
                height 30px
                line-height 30px
                padding 0
                border none
                border-radius 15px
                background linear-gradient(-55deg, #1A62DB, #2A9BFB)
                font-size 16px
                color #fff
                &.disable
                  background linear-gradient(to bottom right, #5b5b5b, #828282)
                &.ban
                  background #fff
                  border 1px solid #1E88E4
                  color #1E88E4
</style>
