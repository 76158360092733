<template>
  <div class="online-reward">
    <div v-show="mode == 1" class="mini" @click="changeMode(2)">
      <img src="./img/mini.png" alt="在线奖励" />
      <p class="timer" :class="{ gray: allRewardGet }">
        <count-down
          ref="miniDownTime"
          v-if="minDownTime > 0 && !waitGet"
          class="text"
          :tip-text="''"
          :seconds-txt="''"
          :end-text="'领取'"
          :start-time="startTime"
          :end-time="minDownTime"
        />
        <span v-if="waitGet && !allRewardGet" class="text">领取</span>
        <span v-if="allRewardGet" class="text">已领完</span>
      </p>
    </div>
    <transition name="slide-in">
      <div v-show="mode == 2" class="plus">
        <div class="title">
          <img src="./img/title.png" alt="翻牌奖励" />
        </div>
        <i class="close cubeic-close" @click="changeMode(1)" />
        <div class="content">
          <ul class="list">
            <li
              v-for="(item, inx) in missionList"
              :key="item.id"
              class="item"
              @click="_getOnlineReward(inx)"
            >
              <div class="flipper" :class="{ rotate: item.status }">
                <div class="face">
                  <p v-show="item.dpp > 0">
                    <img src="./img/dpp.png" alt="dpp" class="dpp" width="45" />
                    <span>+{{ item.dpp }}</span>
                  </p>
                  <p v-show="item.exp > 0">
                    <img src="./img/exp.png" alt="exp" class="exp" width="16" />
                    <span>+{{ item.exp }}</span>
                  </p>
                </div>
                <div class="back" />
              </div>
              <div class="btn">
                <cube-button v-if="item.status == 1" class="ban">
                  已领取
                </cube-button>
                <cube-button
                  v-if="
                    item.status == 0 && item.time <= 0 && user.barrageNum > 0
                  "
                >
                  领取
                </cube-button>
                <cube-button
                  v-if="item.status == 0 && item.time > 0"
                  class="timer"
                >
                  <count-down
                    ref="itemDownTime"
                    :tip-text="''"
                    :seconds-txt="''"
                    :end-text="'领取'"
                    :start-time="item.startTime"
                    :end-time="item.endTime"
                    @end_callback="_postWatchLog"
                  />
                </cube-button>
                <cube-button
                  v-if="
                    item.status == 0 && item.time <= 0 && user.barrageNum <= 0
                  "
                  class="line"
                >
                  弹幕 / 0
                </cube-button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getOnlineRewardList, postWatchLog, getOnlineReward } from "@/api/room";
import countDown from "@/components/base/countdown/countdown";
import { mapState, mapActions, mapMutations } from "vuex";
import { WATCH_TIME_LOG } from "@/api/config";

const TODAY = new Date(new Date().toLocaleDateString()).getTime();
const LOOP_TIME = 60000;

export default {
  components: {
    countDown
  },
  data() {
    return {
      mode: 0 /**0-全部隐藏,1-mini,2-plus */,
      missionList: [] /** 在线时长奖励列表 */,
      allRewardGet: false /** 是否所有奖励都已领取 */,
      startTime: 0 /** 记录开始倒计时的时间 */,
      minDownTime: 0 /** 从奖励列表中获取倒计时最短的任务时间 */,
      waitGet: 0 /** 有无待领取奖励 */
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      room: state => state.room.roomInfo,
      ready: state => state.room.roomReady
    })
  },
  watch: {
    ready(status) {
      if (status) {
        this.roomId = this.room.id;
        this._getOnlineRewardList();
      }
    }
  },
  beforeDestroy() {
    this._postWatchTime(this.roomId);
    this.timer = null;
  },
  mounted() {
    this.listenWatchTime();
  },
  methods: {
    ...mapActions({
      getUserInfo: "user/_getUserInfo"
    }),
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    changeMode(mode) {
      this.mode = mode;
      if (this.mode == 2 && this.user.barrageNum <= 0) {
        this.getUserInfo();
      }
    },
    async _getOnlineRewardList() {
      if (!this.token) return;
      if (this.listLoading) return;
      this.listLoading = true;
      this.waitGet = 0;
      this.minDownTime = 0;
      this.allRewardGet = false;
      const res = await getOnlineRewardList();
      this.listLoading = false;
      if (!res) return;
      this.missionList = res.object;
      if (this.missionList.length <= 0) {
        this.changeMode(0);
        return;
      }
      this.startTime = new Date().getTime();
      //是否有未领取奖励项
      const hasGetReward = this.missionList.findIndex(item => item.status < 1);
      //是否已领取所有奖励
      this.allRewardGet = hasGetReward <= -1;
      if (this.allRewardGet) {
        this.changeMode(0);
        return;
      }
      if (this.mode == 0) {
        this.changeMode(1);
      }
      // 获取最近倒计时
      this.getMinTime();
    },
    getMinTime() {
      const times = [];
      // const curTime = new Date().getTime();
      for (let i = 0; i < this.missionList.length; i++) {
        const item = this.missionList[i];
        item.startTime = this.startTime;
        if (item.time > 0) {
          item.endTime = this.startTime + item.time;
          times.push(item.endTime);
        }
        if (item.time <= 0 && item.status < 1) {
          this.waitGet = 1;
        }
      }
      this.minDownTime = times.length > 0 ? Math.min.apply(null, times) : 0;
      this.$nextTick(() => {
        if (this.$refs.itemDownTime) {
          this.$refs.itemDownTime.forEach(item => {
            item.reload();
            item.gogogo();
          });
        }
        if (this.$refs.miniDownTime) {
          this.$refs.miniDownTime.reload();
          this.$refs.miniDownTime.gogogo();
        }
      });
    },
    async _getOnlineReward(inx) {
      const item = this.missionList[inx];
      if (item.status == 0 && item.time <= 0 && this.user.barrageNum <= 0) {
        this.toast("发送任意一条弹幕即可领取奖励");
        return;
      }
      if (item.status == 0 && item.time > 0) {
        this.toast("还未到领取时间哦~");
        return;
      }
      if (item.status == 1) {
        this.toast("奖励已领取~");
        return;
      }
      if (this.getOnlineRewardLoading) return;
      this.getOnlineRewardLoading = true;
      const params = {
        rewardsId: item.id
      };
      const res = await getOnlineReward(params);
      this.getOnlineRewardLoading = false;
      if (!res) return;
      const resData = res.object;
      this.$set(this.missionList, inx, {
        ...item,
        status: 1,
        time: 0,
        dpp: resData.dpp,
        exp: resData.exp
      });
      this.getUserInfo();
      if (inx == this.missionList.length - 1) {
        this.allRewardGet = true;
      } else {
        this.waitGet = 0;
        this.minDownTime = 0;
        this.allRewardGet = false;
        this.getMinTime();
      }
      // this._getOnlineRewardList();
    },
    async _postWatchLog() {
      if (this.watchLoding) return;
      this.watchLoding = true;
      const time = new Date().getTime() - this.startTime;
      const params = {
        time,
        roomId: this.room.id
      };
      const res = await postWatchLog(params);
      this.watchLoding = false;
      if (!res) return;
      this._getOnlineRewardList();
    },
    _postWatchTime(roomId) {
      if (!this.token || this.postLoading || !this.startTime) return;
      this.postLoading = true;
      const time = new Date().getTime() - this.startTime;
      const url = `${process.env.VUE_APP_API}:8088/admin/api/live/browseWatchTimeGet.json?userId=${this.user.id}&time=${time}&roomId=${roomId}`;
      const image = new Image();
      image.src = url;
      image.style.display = "none";
      document.body.appendChild(image);
      this.postLoading = false;
      this.startTime = 0;
    },
    listenWatchTime() {
      window.addEventListener("unload", () => {
        this._postWatchTime(this.room.id);
      });
    },
    //获取当前观看时长
    checkWatchTime() {
      const watchStoreJson = localStorage.getItem(WATCH_TIME_LOG);
      if (!watchStoreJson) return 0;
      const watchStore = JSON.parse(watchStoreJson);
      if (TODAY != watchStore.day) return 0;
      return !watchStore.token || watchStore.token == this.token
        ? watchStore.time
        : 0;
    },
    loopPostWatchTime() {
      if (this.allRewardGet || !this.minDownTime || this.timer) {
        clearTimeout(this.timer);
      }
      if (this.allRewardGet || !this.minDownTime) {
        return;
      }
      this.timer = setTimeout(() => {
        const today = new Date(new Date().toLocaleDateString()).getTime();
        const currentWatchTime = this.checkWatchTime() + LOOP_TIME;
        localStorage.setItem(
          WATCH_TIME_LOG,
          JSON.stringify({
            token: this.token,
            day: today,
            time: currentWatchTime
          })
        );
        this._postWatchLog();
        this.loopPostWatchTime();
        this.timer = null;
      }, LOOP_TIME);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.online-reward
  position relative
  .mini
    position relative
    padding-bottom 8px
    margin-bottom 8px
    img
      width 69px
    .timer
      position absolute
      left 50%
      bottom 0
      min-width 36px
      height 14px
      line-height 14px
      background linear-gradient(270deg, #FE8433, #FF4200)
      border-radius 7px
      font-size 10px
      font-weight bold
      color #fff
      transform translateX(-50%)
      &.gray
        background #BEBDB9
        color #F9F8FB
      .text
        display inline-block
        transform scale(.8)
  .plus
    position fixed
    right 0
    bottom 0
    left 0
    height 437px
    background url(./img/bg.png) no-repeat center center
    background-size 100% 100%
    .title
      padding 3px 0
      font-size 0
      text-align center
      img
        width 272px
    .close
      position absolute
      top 14px
      right 10px
      font-size 22px
      color #fff
    .content
      padding 15px 76px 0
      .list
        display flex
        flex-wrap wrap
        .item
          position relative
          flex 0 0 77px
          margin-right 69px
          perspective 1000
          overflow hidden
          &:nth-of-type(2n+0)
            margin-right 0
          &:nth-of-type(1)
            .back
              background-image url(./img/back-1.png)
          &:nth-of-type(2)
            .back
              background-image url(./img/back-2.png)
          &:nth-of-type(3)
            .back
              background-image url(./img/back-3.png)
          &:nth-of-type(4)
            .back
              background-image url(./img/back-4.png)
          .flipper
            position relative
            width 100%
            height 128px
            transition 0.6s
            transform-style preserve-3d
            &.rotate
              transform rotateY(180deg)
            .face,.back
              position absolute
              top 0
              right 0
              bottom 0
              left 0
              background-repeat no-repeat
              background-position center center
              background-size 100%
              transition 0.6s
              transform-style preserve-3d
              backface-visibility hidden
            .face
              display flex
              flex-direction column
              justify-content center
              transform  rotateY(-180deg)
              background-image url(./img/face.png)
              p
                position relative
                padding 6px 0 6px 24px
                font-size 11px
                color #FF2B37
                img
                  position absolute
                  bottom 6px
                  &.dpp
                    left 0
                  &.exp
                    left 14px
            .back
              background-repeat no-repeat
              background-position center center
          .btn
            width 100%
            padding 10px 0 18px
            font-size 0
            .cube-btn
              display inline-block
              width auto
              height 16px
              line-height 16px
              background linear-gradient(270deg, #FE8433, #FF4200)
              padding 0 4px
              border-radius 8px
              font-size 10px
              color #F9F8FB
              &.line
                background none
                border 1px solid
                // border-image linear-gradient(270deg, #FF3A21, #FE5C28) 1 1
                border-radius 8px
                color #FF4200
              &.timer
                background #BEBDB9
                color #F9F8FB
              &.ban
                background #FFB289
                color #F9F8FB
              .text
                display inline-block
                transform scale(.8)
</style>
