import axios from "@/axios";

/**
 * 获取通道详情
 * @param {Number} id
 */
export function getLiveDetails(params) {
  const url = `live/live_room/query.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取im登录信息
 */
export function getIMLoginInfo() {
  const url = `live_user/getIMLoginInfo.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取直播间一周送礼排行榜
 * @param {Number} id 直播间id
 */
export function getWeekRank(id) {
  const url = `live/gift/query/weekly_best_sellers.json`;
  let params = {
    liveId: id
  };
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取直播间观看人数
 * @param {Number} id 直播间id
 */
export function getLookCount(id) {
  const url = `live/look/count.json`;
  let params = {
    roomId: id
  };
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取直播间竞猜
 * @param {Number} id 直播间id
 */
export function getRoomGuess(id) {
  const url = `live/guess/liveUserGuess.json`;
  const params = {
    liveId: id
  };
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 参与直播间竞猜
 */
export function guessPlay(params) {
  const url = `live/guess/liveUserBet.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 直播间竞猜周盈利榜
 */
export function weeklyBestProfit(params) {
  const url = `live/guess/weeklyBestProfit.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 直播间竞猜月盈利榜
 */
export function monthBestProfit(params) {
  const url = `live/guess/monthBestProfit.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 直播间竞猜周亏损榜
 */
export function weeklyBestDeficit(params) {
  const url = `live/guess/weeklyBestDeficit.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 直播间竞猜月亏损榜
 */
export function monthBestDeficit(params) {
  const url = `live/guess/monthBestDeficit.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 在线时长列表
 */
export function getOnlineRewardList() {
  const url = `live/liveQuestRewardsList.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 领取在线时长奖励
 */
export function getOnlineReward(params) {
  const url = `live_user/drawRewards.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 主动上报观看时长-奖励
 */
export function postWatchLog(params) {
  const url = `live/browseWatchTime.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 被动上报观看时长-奖励
 */
export function postWatchTime(id, time) {
  const url = `live/browseWatchTimeGet.json?userId=${id}&time=${time}`;
  return axios.get(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * @returns 礼物列表
 */
export function getGiftList() {
  const url = `live/gift/listAll.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 赠送礼物
 */
export function giveGifts(params) {
  const url = `payment/userGivingGifts.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 上报用户直播间观看时长
 */
export function reportRoomPlayTime(params) {
  const url = `live_channel/browseRoom.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 查询其他用户信息
 */
export function queryUserInfo(params) {
  const url = `user_friend/checkFriend.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 用户禁言或解禁
 */
export function banUser(params) {
  const url = `live_user/updateUserInteractState.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取弹幕颜色列表
 * @returns
 */
export function getChatColorList() {
  const url = `queryChatColor.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 解锁弹幕
 * @param {*} params
 * @returns
 */
export function unLockChatColor(params) {
  const url = `unlockChatColor.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取粉丝牌列表
 * @returns
 */
export function getFansTagList() {
  const url = `anchorFas_list.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 佩戴粉丝牌
 * @returns
 */
export function useFansTag(params) {
  const url = `userAddFans.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 查询禁言状态
 * @returns
 */
export function _checkUserInteractState() {
  const url = `live_user/checkUserInteractState.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 查询抽奖信息
 * @returns
 */
export function _getLotteryInfo(params) {
  const url = `live/liveChat/liveLotteryDrawCombo.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 参与直播间抽奖
 * @returns
 */
export function _joinLottery(params) {
  const url = `partake_lotterydraw/liveUserPartakeLotteryDraw.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取节目单
 * @returns
 */
export function _getProgramList(params) {
  const url = `live/live_room/queryProgram.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取投票配置信息
 * @returns
 */
export function _getVoteInfo(params) {
  const url = `view/vote/seeLiveUserVoteResult.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 投票
 * @returns
 */
export function _joinVote(params) {
  const url = `vote/liveUserPartakeVote.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
