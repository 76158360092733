<template>
  <div v-show="showFlag" class="room-guess-bet">
    <div class="bg" @click="hide" />
    <transition name="slide-in">
      <div v-show="showFlag" class="content">
        <div class="top">
          <h2 class="title">
            {{ info.theme }}
            <span :class="{ a: info.pick == 1, b: info.pick == 2 }">
              （我猜：{{ info.choice }}）
            </span>
          </h2>
          <div class="tips">
            <p>
              <span><img src="./img/profit.png" class="profit"/></span>
              <span class="desc">赢则获得</span>
              <span class="num blue" v-text="profit" />
              <span class="more">（含本金）</span>
            </p>
            <p>
              <span><img src="./img/odds.png" class="odds"/></span>
              <span class="desc">赔率</span>
              <span class="num orange" v-text="info.odds" />
              <span class="more">（以封盘时的赔率为准，结算收益）</span>
            </p>
          </div>
          <div class="raise">
            <div class="input">
              <cube-input v-model="chip" type="number" :clearable="true" />
            </div>
            <div class="press-box">
              <div class="clear">清空</div>
              <div class="press">
                <press-bar
                  :percent="percent"
                  @percentChange="onProgressBarChange"
                />
              </div>
              <div class="max">最大</div>
            </div>
            <ul class="options">
              <li
                v-for="(chip, inx) in chipList"
                :key="chip"
                class="item"
                :class="{ active: inx == chipInx }"
                v-text="chip"
                @click="chipRaise(chip, inx)"
              />
              <li
                class="item"
                :class="{ active: chipInx == 4 }"
                @click="chipAll()"
              >
                全部
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom">
          <div class="l">
            <div class="inner">
              <div>
                <img src="./img/dpp.png" class="dpp" />
                DPP: <b v-text="user.dppIntegral" />
              </div>
            </div>
          </div>
          <div class="r">
            <div class="d" @click="_guessPlay">
              <div class="inner">
                <div class="text">参与竞猜</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import pressBar from "@/components/base/progress-bar/progress-bar";
import { guessPlay } from "@/api/room";
export default {
  components: {
    pressBar
  },
  data() {
    return {
      showFlag: false,
      info: {},
      pick: -1,
      chip: 0,
      chipList: [10, 100, 1000, 10000],
      chipInx: -1
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      live: state => state.room.roomInfo
    }),
    profit() {
      return parseInt(this.chip * this.info.odds * 0.9 + this.chip);
    },
    percent() {
      return this.chip / this.user.dppIntegral;
    }
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
      this.info = {};
      this.pick = -1;
      this.chipInx = -1;
    },
    chipRaise(chip, inx) {
      const limitMax = this.user.dppIntegral;
      if (limitMax <= 0) {
        this.toast("您的余额不足！");
        return;
      }
      const inputChip = !this.chip ? 0 : parseInt(this.chip);
      if (
        inputChip > limitMax ||
        chip > limitMax ||
        inputChip + chip > limitMax
      ) {
        this.chip = limitMax;
      } else {
        this.chip = inputChip + chip;
      }
      this.chipInx = inx;
    },
    chipAll() {
      this.chip = this.user.dppIntegral;
      this.chipInx = 4;
    },
    onProgressBarChange(percent) {
      this.chip = parseInt(this.user.dppIntegral * percent);
    },
    _guessPlay() {
      if (this.chip < 10) {
        this.toast("投注额不能小于10");
        return;
      }
      if (this.raiseLoading) {
        return;
      }
      this.raiseLoading = true;
      const params = {
        liveId: this.live.id,
        guessComId: this.info.guessComId,
        guessThemeId: this.info.guessThemeId,
        choiceItem: this.info.pick,
        betChips: this.chip
      };
      guessPlay(params).then(res => {
        this.raiseLoading = false;
        if (!res) {
          return;
        }
        if (res.code != 1) {
          this.toast(res.message);
          return;
        }
        if (res.object.code != 1) {
          this.toast(`还能下注${parseInt(res.object.data)}斗币`);
          this.chip = res.object.data;
        } else {
          this.hide();
          this.chip = 0;
          this.$emit("done", this.info.pick);
        }
      });
    },
    toLink(link) {
      this.$router.push(link);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.room-guess-bet
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  z-index 12
  .bg
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    background rgba(0, 0, 0, .6)
  .content
    position absolute
    right 0
    bottom 0
    left 0
    .top
      border-top-left-radius 15px
      border-top-right-radius 15px
      background #fff
      .title
        padding 11px 6px
        border-bottom 1px dashed #6B6B6B
        font-size 17px
        font-weight bold
        color #6B6B6B
        span
          font-size 14px
          &.a
            color #1E88E4
          &.b
            color #F6210B
      .tips
        padding 18px 0 21px 18px
        border-bottom 1px dashed #6B6B6B
        p
          line-height 24px
          span
            display inline-block
            vertical-align middle
        .profit
          vertical-align middle
          width 21px
          margin-right 8px
        .odds
          vertical-align middle
          width 15px
          margin 0 11px 0 3px
        .desc
          font-size 17px
          font-weight bold
          color #6B6B6B
          margin-right 4px
        .num
          font-size 17px
          font-weight bold
          &.blue
            color #1E88E4
          &.orange
            color #F6700B
        .more
          font-size 14px
          color #B8B8B8
      .raise
        padding 20px 18px 18px
        .input
          width 100%
          border 1px solid #2999FB
          border-radius 20px
          overflow hidden
          input
            border none
        .press-box
          display flex
          padding 0 10px
          .press
            flex 0 0 243px
          .clear,.max
            flex 1
            line-height 30px
            font-size 14px
            text-align center
            color #B8B8B8
        .options
          .item
            display inline-block
            width 57px
            height 28px
            line-height 28px
            margin-right 13px
            border 1px solid #E8EAF1
            border-radius 14px
            text-align center
            font-size 17px
            color #6B6B6B
            &.active
              border-color #2A9AFB
              color #2A9AFB
            &:last-child
              margin-right 0
    .bottom
      display flex
      width 100%
      height  49px
      background #fff
      margin-top 2px
      padding 0 0 0 13px
      .inner
        display flex
        flex-direction column
        justify-content center
        height 100%
      .l
        flex 1
        .inner
          font-size 14px
          color #6B6B6B
          .dpp
            width 25px
            vertical-align middle
          b
            color #F6700B
      .r
        flex 1
        text-align right
        .d
          display inline-block
          width 125px
          height 100%
          background linear-gradient(to right, #1B7DFC, #2A9BFB)
          text-align center
          font-size 21px
          color #fff
</style>
