<template>
  <div class="room-chat-wrap">
    <div ref="chat" class="scroll-wrap" :class="{ ornamentShow: token }">
      <div class="scroll-inner">
        <cube-scroll
          ref="scroll"
          :data="list"
          :scroll-events="['scroll-end']"
          @scroll-end="onScrollEnd"
          :options="{ click: true, bounce: false }"
        >
          <ul ref="list" class="list">
            <li ref="listGroup" v-for="msg in list" :key="msg.id" class="item">
              <!-- 欢迎用户 -->
              <div
                v-if="
                  msg.userId == 30 &&
                    msg.type == 1 &&
                    msg.textMsgType == 0 &&
                    msg.welcomeUserNickName
                "
                class="inner-wrap"
              >
                <gm
                  v-if="msg.welcomeUserRoleType > 0"
                  :roleType="msg.welcomeUserRoleType"
                />
                <star v-if="msg.honorStatus == 1" :level="msg.lv" />
                <vip v-if="msg.welcomeUserType == 2" />
                <cute v-if="msg.welcomeUserNewPeople == 1" style="top: -2px" />
                <fans v-if="msg.fansImage" :src="msg.fansImage" />
                <level :level="msg.lv" />
                <span class="name" v-text="msg.welcomeUserNickName" />
                <span class="inner">欢迎来到直播间</span>
              </div>
              <!-- 禁言或解禁 -->
              <div
                v-if="msg.userId == 30 && msg.accountState"
                class="inner-wrap"
              >
                <trumpet />
                <span class="name" v-text="msg.punishUserNickName + '  '" />
                <span
                  class="inner"
                  :class="msg.accountState == 2 ? 'disable' : 'no-disable'"
                  v-text="msg.content"
                />
              </div>
              <!-- 公告 -->
              <div
                v-else-if="
                  msg.userId == 30 && msg.type == 1 && msg.textMsgType == 1
                "
                class="inner-wrap notice"
              >
                <trumpet />
                <span class="inner" v-text="msg.content" />
              </div>
              <!-- PICK -->
              <div v-if="msg.userId == 30 && msg.type == 18" class="inner-wrap">
                <fans v-if="msg.fansImage" :src="msg.fansImage" />
                <span class="name" v-text="msg.nickName" />
                <span class="pick">
                  PICK了{{ msg.anchorName }}，主播更爱你了
                </span>
              </div>
              <div v-if="msg.userId != 30" class="inner-wrap">
                <img v-if="msg.medal" :src="msg.medal" class="super-symbol" />
                <gm v-if="msg.roleType > 0" :roleType="msg.roleType" />
                <star v-if="msg.honorStatus == 1" :level="msg.lv" />
                <vip v-if="msg.userType == 2" />
                <cute v-if="msg.newPeople == 1" class="diff" />
                <fans v-if="msg.fansImage" :src="msg.fansImage" />
                <level :level="msg.lv" />
                <span
                  class="name"
                  v-text="`${msg.nickName}：`"
                  @click="showBanWin(msg)"
                />
                <!-- 用户送礼 -->
                <span
                  v-if="msg.type == 2"
                  class="gift inner"
                  v-text="msg.content"
                />
                <!-- 用户发言 -->
                <span
                  v-if="msg.type == 1"
                  class="inner"
                  :style="`color: ${msg.chatColor}`"
                  v-html="$xss(msg.content)"
                />
              </div>
            </li>
          </ul>
        </cube-scroll>
      </div>
      <div v-show="unReadMsg" class="tips" @click="_onScrollBottom">
        有新的弹幕消息
      </div>
    </div>
    <div
      v-if="token"
      ref="chatInputWrap"
      class="chat-input-wrap"
      :class="{ focus: isIosFocus }"
    >
      <div class="l" @click="showGiftBox">
        <img src="./img/gift.png" class="ic-gift" alt="礼物盒子" />
      </div>
      <div class="m">
        <input
          ref="chatInput"
          v-model="chat"
          type="text"
          placeholder="发个弹幕呗"
          @keyup.13="sendMsg"
          @focus="chatInputFocus"
          @blur="chatInputBlur"
        />
      </div>
      <div class="r">
        <cube-button @click="sendMsg">发送</cube-button>
      </div>
    </div>
    <div v-else class="chat-input-wrap">
      <div class="mask" @click="toLink('/login')">
        <span>登录</span>发弹幕，参与主播互动
      </div>
    </div>
    <banned-user ref="bannedRef" />
    <gift-box ref="giftBox" />
    <gift-bar ref="giftBar" />
    <ornament ref="ornamentRef" :ios-focus="isIosFocus" />
  </div>
</template>
<script>
import giftBox from "@/components/room/gifts";
import giftBar from "@/components/room/gifts/animated/bar";
import bannedUser from "@/components/room/banned/banned";
import ornament from "@/components/room/ornament";
import gm from "@/components/room/icon/owner/gm";
import vip from "@/components/room/icon/vip/vip";
import cute from "@/components/room/icon/cute/cute";
import fans from "@/components/room/icon/fans/fans";
import level from "@/components/room/icon/level/level";
import star from "@/components/room/icon/star/star";
import trumpet from "@/components/room/icon/trumpet/trumpet";
import { mapState, mapMutations } from "vuex";
import { send } from "@/api/user";
import { _joinLottery } from "@/api/room";

const ua = navigator.userAgent;
const IOS = /iPad|iPhone|iPod/.test(ua);
// function uaIncludes(str) {
//   return ua.indexOf(str) !== -1;
// }
function testScrollType() {
  if (IOS) {
    if (/OS 1[5-9]_[0-9]\D/.test(ua)) {
      /**
       * 只处理IOS15以上版本
       */
      return 1;
    }
    // 用 `scrollTop` 的方式
    return 0;
  }
  // 其它的用 `scrollIntoView` 的方式
  return 2;
}
const scrollType = testScrollType();

export default {
  components: {
    gm,
    vip,
    cute,
    fans,
    level,
    star,
    trumpet,
    giftBox,
    giftBar,
    bannedUser,
    ornament
    // manager
  },
  data() {
    return {
      isScorllDown: false,
      scrollCurY: 0,
      unReadMsg: 0,
      chat: "",
      isIosFocus: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info,
      list: state => state.room.roomBarrageList,
      room: state => state.room.roomInfo,
      deleteMsg: state => state.room.deleteMsg,
      lotteryInfo: state => state.room.lotteryInfo,
      chatText: state => state.room.chatText,
      joinLotteryHistory: state => state.room.joinLotteryHistory
    })
  },
  watch: {
    list() {
      if (this.isScorllDown) {
        ++this.unReadMsg;
        return;
      }
      this.$nextTick(() => {
        if (
          this.$refs.list.offsetHeight > this.$refs.scroll.scroll.wrapperHeight
        ) {
          setTimeout(() => {
            this._onScrollBottom();
          }, 20);
        }
      });
    },
    deleteMsg(msg) {
      const inx = this.list.findIndex(item => item.id == msg.id);
      this.delMsg(inx);
    },
    chatText(text) {
      this.chat = text;
    }
  },
  methods: {
    ...mapMutations({
      delMsg: "room/DEL_ROOM_BARRAGE_LIST",
      toast: "global/SET_TOAST",
      setLotteryHistory: "room/SET_JOIN_LOTTERY_HISTORY",
      setChatText: "room/SET_CHAT_TEXT"
    }),
    onScrollEnd(pos) {
      if (pos.y > this.scrollCurY) {
        this.isScorllDown = true;
      } else {
        this.unReadMsg = 0;
        this.isScorllDown = false;
      }
    },
    _onScrollBottom() {
      this.$refs.scroll.scrollTo(0, this.$refs.scroll.scroll.maxScrollY);
      this.scrollCurY = this.$refs.scroll.scroll.y;
      this.isScorllDown = false;
      this.unReadMsg = 0;
    },
    async sendMsg() {
      if (!this.token) {
        this.toLink("/login");
        return;
      }
      if (this.room.isChat != 1) {
        this.toast("直播间禁止发言");
        return;
      }
      if (this.room.payState == 1 && this.user.userType != 2) {
        this.toast("金币赛事直播，只有会员才可发布评论");
        return;
      }
      if (this.chat.trim() == "") {
        return;
      }
      if (this.msgLoading) return;
      this.msgLoading = true;
      const msg = this.chat;
      this.chat = "";
      this.$refs.chatInput.blur();
      const params = {
        liveId: this.room.id,
        content: msg
      };
      const res = await send(params);
      this.msgLoading = false;
      if (res) {
        if (msg == this.lotteryInfo.lotteryDrawCmd) {
          this.joinLottery();
        }
      }
    },
    async joinLottery() {
      this.setChatText("");
      if (this.joinLotteryHistory == this.lotteryInfo.id) return;
      const params = {
        liveId: this.room.id
      };
      const res = await _joinLottery(params);
      if (res) {
        this.setLotteryHistory(this.lotteryInfo.id);
        this.$createToast({
          txt: "参与抽奖成功，祝你好运",
          type: "correct"
        }).show();
      }
    },
    toLink(link) {
      this.$router.push(link);
    },
    chatInputFocus() {
      // this.showOrnamentWin();
      const scrollIntoView = () => {
        if (scrollType == 0) return;
        if (scrollType == 1) {
          this.isIosFocus = true;
          document.body.scrollTop = document.body.scrollHeight;
        } else {
          this.$refs.chatInputWrap.scrollIntoView(false);
        }
      };
      setTimeout(scrollIntoView, 300);
      this.timer = setTimeout(scrollIntoView, 1000);
    },
    chatInputBlur() {
      clearTimeout(this.timer);
      this.isIosFocus = false;
      // 某些情况下收起键盘后输入框不收回，页面下面空白
      // 比如：闲鱼、大麦、乐动力、微信
      if (scrollType && IOS) {
        // 以免点击快捷短语无效
        setTimeout(() => {
          document.body.scrollIntoView();
        });
      }
      if (scrollType == 1) {
        document.body.scrollTop = 0;
        this.sendMsg();
      }
    },
    showGiftBox() {
      this.$refs.giftBox.show();
    },
    showBanWin(msg) {
      console.log(msg);
      this.$refs.bannedRef.info = msg;
      this.$refs.bannedRef.show();
    },
    showOrnamentWin() {
      this.$refs.ornamentRef.show();
    }
  }
};
</script>

<style lang="stylus" scoped>
.room-chat-wrap
  position relative
  display flex
  flex-direction column
  flex 1
  padding-top 4px
  .scroll-wrap
    position relative
    width 100%
    height calc(100% - 50px)
    overflow hidden
    &.ornamentShow
      height calc(100% - 50px - 34px)
    .scroll-inner
      position absolute
      top 0
      right 0
      bottom 0
      left 0
    .list
      overflow hidden
      .item
        font-size 13px
        .inner-wrap
          padding 5px 10px 5px
          line-height 15px
          color #383A40
          white-space normal
          word-wrap break-word
          word-break break-all
          &.notice
            background #E5FFFC
            .inner
              color #9B39F4
          .name,.inner,.emoticon
            vertical-align middle
          .name
            color #2B94FF
          .gift
            color #FF69B4
          .disable
            color #FE5500
          .no-disable
            color #9b39f4
          .pick
            color red
          .diff
            top -1.5px
          .super-symbol
            vertical-align middle
            height 15px
            margin-right 6px
  .tips
    position absolute
    left 50%
    margin-left -66px
    bottom 20px
    background-color #1e88e4
    padding 5px 10px
    border-radius 5px
    color #fff
    z-index 10
  .chat-input-wrap
    position fixed
    bottom 0
    left 0
    flex 0 0 50px
    width 100%
    height 50px
    display flex
    align-items center
    padding 0 12px 0 8px
    background #fff
    overflow hidden
    transform translateZ(0)
    z-index 10
    &.focus
      bottom 30px
      z-index 12
    .l
      flex 0 0 40px
      margin-right 8px
      font-size 0
      img
        width 40px
    .m
      flex 1
      border 1px solid #E5E8F0
      input
        display block
        width 100%
        height 33px
        line-height 33px
        padding 0 5px
        box-shadow none
        appearance none
        background transparent
    .r
      flex 0 0 58px
      background #1E88E4
      border 1px solid #1E88E4
      button
        height 33px
        line-height 33px
        padding 0
        background #1E88E4
        font-size 13px
        color #fff
    .mask
      position absolute
      width 100%
      height 33px
      line-height 33px
      text-align center
      span
        color #1E88E4
</style>
