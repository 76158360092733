<template>
  <span class="icon-star" />
</template>
<script>
export default {
  props: {
    level: {
      type: Number,
      default() {
        return 1;
      }
    }
  }
};
</script>
<style lang="stylus">
.icon-star
  position relative
  top -3px
  display inline-block
  width 32px
  height 22px
  vertical-align middle
  margin-right 6px
  background url(./star.png) no-repeat center center
  background-size 100%
</style>
