<template>
  <div class="fans-tag-box">
    <div class="title-box">
      <div class="l">
        <p class="title">粉丝头衔</p>
        <p class="desc">
          参与PICK可以增加<span>主播粉丝值</span>，粉丝值达到
          <span> {{ pickConfig.fansShow }} </span>
          即可佩戴头衔
        </p>
      </div>
      <div class="r">
        <cube-checkbox
          v-model="isSelectTitle"
          shape="square"
          @input="delFansTag"
        >
          不佩戴头衔
        </cube-checkbox>
      </div>
    </div>
    <ul v-if="list.length" class="list">
      <li v-for="item in list" :key="item.id" class="item">
        <div class="top">
          <div class="l">
            <cube-checkbox
              ref="checkboxRef"
              v-model="item.checked"
              :option="{
                value: item.id,
                disabled: item.fansValue < pickConfig.fansShow
              }"
              @input="selFansTag"
            >
              <img
                :src="item.fansIma"
                :class="{ lock: item.fansValue < pickConfig.fansShow }"
              />
            </cube-checkbox>
          </div>
          <!-- <div class="r">
            <img
              :src="item.fansIma"
              :class="{ lock: item.fansValue < pickConfig.fansShow }"
            >
          </div> -->
        </div>
        <div class="bottom">
          粉丝值：<b>{{ item.fansValue }}</b>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { getPickConfig } from "@/api/index";
import { getFansTagList, useFansTag } from "@/api/room";
import { mapState } from "vuex";
export default {
  data() {
    return {
      pickConfig: {},
      isSelectTitle: true,
      list: []
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info
    })
  },
  created() {
    this._getPickConfig();
    this._getFansTagList();
  },
  methods: {
    async _getPickConfig() {
      if (!this.token) return;
      const res = await getPickConfig();
      if (!res) return;
      this.pickConfig = res.object;
    },
    async _getFansTagList() {
      if (!this.token) return;
      const res = await getFansTagList();
      if (!res) return;
      this.list = res.object;
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].checked = this.list[i].select == 1;
        if (this.list[i].select == 1) {
          this.isSelectTitle = false;
        }
      }
    },
    selFansTag(val) {
      const inx = this.list.findIndex(item => item.id == val);
      if (inx > -1) {
        this.clearFansTag();
        this.$set(this.list, inx, {
          ...this.list[inx],
          checked: true
        });
        this._useFansTag(val);
      } else {
        this.cancelFansTag();
      }
    },
    clearFansTag() {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].checked = false;
      }
    },
    delFansTag() {
      this.clearFansTag();
      this.cancelFansTag();
    },
    async _useFansTag(val) {
      const params = {
        anchorId: val
      };
      const res = await useFansTag(params);
      if (!res) return;
      this.isSelectTitle = false;
    },
    async cancelFansTag() {
      await useFansTag();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.fans-tag-box
  position absolute
  right 0
  bottom 100%
  left 0
  background #fff
  font-size 10px
  color #666
  .title-box
    display flex
    justify-content space-between
    align-items center
    padding 10px
    .l
      .title
        padding-bottom 3px
      .desc
        position absolute
        left -18px
        transform scale(.8)
        white-space nowrap
        span
          color #1E88E4
  .list
    display flex
    flex-wrap wrap
    justify-content space-between
    padding 0 10px
    .item
      flex 0 0 33.3%
      padding 5px 0
      .top
        display flex
        align-items center
        .l
          .cube-checkbox-label
            display none
        .r
          img
            height 20px
            &.lock
              -webkit-filter grayscale(1)
              filter gray
              filter grayscale(1)
      .bottom
        padding-left 16px
        font-size 10px
        color #666
        b
          color #1E88E4
</style>
